<template>
  <div class="row mb-4">
    <div class="col-md-12">
      <h3 class="card-title-cus">他社媒介・紹介新築等物件</h3>
    </div>
  </div>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <label class="text-nowrap my-2 mr-4 form-check-label"
            >物件名
            <span v-if="ifCompulsory('name')" class="badge badge-red"
              >必 須</span
            >
          </label>
        </div>
        <div class="col-sm-10">
          <div class="cus-colWidth">
            <div class="input-group">
              <input
                v-model="property.name"
                type="text"
                maxlength="99"
                class="form-control"
                :class="{ 'field-error': errorObject.name }"
                :disabled="ifDisabled('name')"
                @focusout="removeEmptyErrorMessage(errorObject.name, 'name')" />
            </div>
            <p v-if="errorObject.name" class="error">
              {{ errorObject.name }}
            </p>
            <div class="row mt-4">
              <div class="col-sm-6 col-xl-5 mt-3">
                <UploadImageFile
                  id="image1"
                  v-model="property.appearance_image_link"
                  text-in-image="外観アップロード"
                  @focusout="
                    dataParams.appearance_image = $event;
                    removeEmptyErrorMessage(
                      dataParams.appearance_image,
                      'appearance_image'
                    );
                  " />
                <div class="text-center fs-16 my-2">
                  外観画像 <span class="badge badge-red">必 須</span>
                  <p
                    v-if="errorObject.appearance_image"
                    class="error text-nowrap">
                    {{ errorObject.appearance_image }}
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-xl-5 mt-3">
                <UploadImageFile
                  id="image2"
                  v-model="property.guide_map_image_link"
                  text-in-image="画像アップロード"
                  @focusout="
                    dataParams.guide_map_image = $event;
                    removeEmptyErrorMessage(
                      dataParams.guide_map_image,
                      'guide_map_image'
                    );
                  " />
                <div class="text-center fs-16 my-2">
                  現地案内図 <span class="badge badge-red">必 須</span>
                  <p
                    v-if="errorObject.guide_map_image"
                    class="error text-nowrap">
                    {{ errorObject.guide_map_image }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-relative text-right mb-2">
    <div class="page-note text-left">
      ※お客様への提示項目は色付きで表示しています
    </div>
  </div>
  <div class="wrap-ct">
    <div class="my-4 mx-3 mx-md-5">
      <div class="custom-form col-xl-11">
        <form>
          <FieldInputText
            v-model="property.building_number"
            :disabled="ifDisabled('building_number')"
            :compulsory="ifCompulsory('building_number')"
            label="棟番号"
            color=""
            max-length="99"
            :error-message="errorObject.building_number"
            @focus-out="
              removeEmptyErrorMessage(
                property.building_number,
                'building_number'
              )
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">所在地-都道府県</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  id="'sl-prefectures'"
                  v-model="dataParams.idPrefectures.address_cd"
                  :class="{ 'field-error': errorObject.idPrefectures }"
                  :options="dataPrefectures"
                  :keyid="'prefectures_cd'"
                  :keytext="'prefectures_name'"
                  :change="
                    removeEmptyErrorMessage(
                      dataParams.idPrefectures.address_cd,
                      'idPrefectures'
                    )
                  "
                  @select="selectPrefecture" />
              </div>
              <p v-if="errorObject.idPrefectures" class="error">
                {{ errorObject.idPrefectures }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">所在地-市区町村</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  id="'sl-city'"
                  v-model="dataParams.idCity"
                  :class="{ 'field-error': errorObject.idCity }"
                  :options="dataCity"
                  :keyid="'city_name'"
                  :keytext="'city_name'"
                  :change="
                    removeEmptyErrorMessage(dataParams.idCity, 'idCity')
                  " />
              </div>
              <p v-if="errorObject.idCity" class="error">
                {{ errorObject.idCity }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">所在地-大字以降</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <textarea
                v-model="property.location_detail"
                type="text"
                :class="{ 'field-error': errorObject.location_detail }"
                maxlength="99"
                class="form-control minh-120"
                @focusout="
                  removeEmptyErrorMessage(
                    property.location_detail,
                    'location_detail'
                  )
                "></textarea>
              <p v-if="errorObject.location_detail" class="error">
                {{ errorObject.location_detail }}
              </p>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅沿線①</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div>
                <div class="custom-select2 w-300 d-inline-block">
                  <FieldSelect2Group
                    id="'sl-railroad'"
                    v-model="dataParams.idRailroad.reins_railroad_cd"
                    :class="{ 'field-error': errorObject.idRailroad }"
                    :options="dataRailroad"
                    :keytextparent="'name'"
                    :keylist="'list'"
                    :keyid="'reins_railroad_cd'"
                    :keytext="'name'"
                    :clear="true"
                    :change="
                      removeEmptyErrorMessage(
                        dataParams.idRailroad.reins_railroad_cd,
                        'idRailroad'
                      )
                    "
                    @select="selectRailroad" />
                </div>
                <div v-if="originalProperty" class="mx-2 d-inline-block">
                  <template
                    v-if="
                      originalProperty.nearest_station_railway_1 &&
                      dataParams.idRailroad.reins_railroad_cd == undefined
                    ">
                    {{ originalProperty.nearest_station_railway_1 }}
                  </template>
                </div>
              </div>
              <p v-if="errorObject.idRailroad" class="error">
                {{ errorObject.idRailroad }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅①</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  id="'sl-station'"
                  v-model="dataParams.idStation"
                  :class="{ 'field-error': errorObject.idStation }"
                  :options="dataStation"
                  :keyid="'station_name'"
                  :keytext="'station_name'"
                  :clear="true"
                  :change="
                    removeEmptyErrorMessage(dataParams.idStation, 'idStation')
                  " />
              </div>
              <p v-if="errorObject.idStation" class="error">
                {{ errorObject.idStation }}
              </p>
            </div>
          </div>
          <FieldInputNumber
            v-model="property.walking_to_nearest_station_1"
            :disabled="ifDisabled('walking_to_nearest_station_1')"
            :compulsory="ifCompulsory('walking_to_nearest_station_1')"
            label="最寄駅①まで徒歩"
            :error-message="errorObject.walking_to_nearest_station_1"
            @focus-out="
              property.walking_to_nearest_station_1 = roundInteger(
                property.walking_to_nearest_station_1
              );
              removeEmptyErrorMessage(
                property.walking_to_nearest_station_1,
                'walking_to_nearest_station_1'
              );
              validateMax(
                property.walking_to_nearest_station_1,
                999,
                ERROR_MESSAGE['3'],
                'walking_to_nearest_station_1'
              );
            " />
          <FieldInputNumber
            v-model="property.bus_to_nearest_station_1"
            :disabled="ifDisabled('bus_to_nearest_station_1')"
            :compulsory="ifCompulsory('bus_to_nearest_station_1')"
            label="最寄駅①までバス"
            color=""
            :error-message="errorObject.bus_to_nearest_station_1"
            @focus-out="
              property.bus_to_nearest_station_1 = roundInteger(
                property.bus_to_nearest_station_1
              );
              removeEmptyErrorMessage(
                property.bus_to_nearest_station_1,
                'bus_to_nearest_station_1'
              );
              validateMax(
                property.bus_to_nearest_station_1,
                999,
                ERROR_MESSAGE['3'],
                'bus_to_nearest_station_1'
              );
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅沿線②</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div>
                <div class="custom-select2 w-300 d-inline-block">
                  <FieldSelect2Group
                    id="'sl-railroad-2'"
                    v-model="dataParams.idRailroad2.reins_railroad_cd"
                    :class="{ 'field-error': errorObject.idRailroad2 }"
                    :options="dataRailroad"
                    :keytextparent="'name'"
                    :keylist="'list'"
                    :keyid="'reins_railroad_cd'"
                    :keytext="'name'"
                    :clear="true"
                    :change="
                      removeEmptyErrorMessage(
                        dataParams.idRailroad2.reins_railroad_cd,
                        'idRailroad2'
                      )
                    "
                    @select="selectRailroad2" />
                </div>
                <div v-if="originalProperty" class="mx-2 d-inline-block">
                  <template
                    v-if="
                      originalProperty.nearest_station_railway_2 &&
                      dataParams.idRailroad2.reins_railroad_cd == undefined
                    ">
                    {{ originalProperty.nearest_station_railway_2 }}
                  </template>
                </div>
              </div>
              <p v-if="errorObject.idRailroad2" class="error">
                {{ errorObject.idRailroad2 }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅②</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  id="'sl-station-2'"
                  v-model="dataParams.idStation2"
                  :class="{ 'field-error': errorObject.idStation2 }"
                  :options="dataStation2"
                  :keyid="'station_name'"
                  :keytext="'station_name'"
                  :change="
                    removeEmptyErrorMessage(dataParams.idStation2, 'idStation2')
                  " />
              </div>
              <p v-if="errorObject.idStation2" class="error">
                {{ errorObject.idStation2 }}
              </p>
            </div>
          </div>
          <FieldInputNumber
            v-model="property.walking_to_nearest_station_2"
            label="最寄駅②まで徒歩"
            :error-message="errorObject.walking_to_nearest_station_2"
            @focus-out="
              property.walking_to_nearest_station_2 = roundInteger(
                property.walking_to_nearest_station_2
              );
              removeEmptyErrorMessage(
                property.walking_to_nearest_station_2,
                'walking_to_nearest_station_2'
              );
              validateMax(
                property.walking_to_nearest_station_2,
                999,
                ERROR_MESSAGE['3'],
                'walking_to_nearest_station_2'
              );
            " />
          <FieldInputNumber
            v-model="property.bus_to_nearest_station_2"
            label="最寄駅②までバス"
            color=""
            :error-message="errorObject.bus_to_nearest_station_2"
            @focus-out="
              property.bus_to_nearest_station_2 = roundInteger(
                property.bus_to_nearest_station_2
              );
              removeEmptyErrorMessage(
                property.bus_to_nearest_station_2,
                'bus_to_nearest_station_2'
              );
              validateMax(
                property.bus_to_nearest_station_2,
                999,
                ERROR_MESSAGE['3'],
                'bus_to_nearest_station_2'
              );
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅沿線③</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div>
                <div class="custom-select2 w-300 d-inline-block">
                  <FieldSelect2Group
                    id="'sl-railroad-3'"
                    v-model="dataParams.idRailroad3.reins_railroad_cd"
                    :class="{ 'field-error': errorObject.idRailroad3 }"
                    :options="dataRailroad"
                    :keytextparent="'name'"
                    :keylist="'list'"
                    :keyid="'reins_railroad_cd'"
                    :keytext="'name'"
                    :clear="true"
                    :change="
                      removeEmptyErrorMessage(
                        dataParams.idRailroad3.reins_railroad_cd,
                        'idRailroad3'
                      )
                    "
                    @select="selectRailroad3" />
                </div>
                <div v-if="originalProperty" class="mx-2 d-inline-block">
                  <template
                    v-if="
                      originalProperty.nearest_station_railway_3 &&
                      dataParams.idRailroad3.reins_railroad_cd == undefined
                    ">
                    {{ originalProperty.nearest_station_railway_3 }}
                  </template>
                </div>
              </div>
              <p v-if="errorObject.idRailroad3" class="error">
                {{ errorObject.idRailroad3 }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">最寄駅③</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  id="'sl-station-3'"
                  v-model="dataParams.idStation3"
                  :class="{ 'field-error': errorObject.idStation3 }"
                  :options="dataStation3"
                  :keyid="'station_name'"
                  :keytext="'station_name'"
                  :change="
                    removeEmptyErrorMessage(dataParams.idStation3, 'idStation3')
                  " />
              </div>
              <p v-if="errorObject.idStation3" class="error">
                {{ errorObject.idStation3 }}
              </p>
            </div>
          </div>
          <FieldInputNumber
            v-model="property.walking_to_nearest_station_3"
            label="最寄駅③まで徒歩"
            :error-message="errorObject.walking_to_nearest_station_3"
            @focusout="
              property.walking_to_nearest_station_3 = roundInteger(
                property.walking_to_nearest_station_3
              );
              removeEmptyErrorMessage(
                property.walking_to_nearest_station_3,
                'walking_to_nearest_station_3'
              );
              validateMax(
                property.walking_to_nearest_station_3,
                999,
                ERROR_MESSAGE['3'],
                'walking_to_nearest_station_3'
              );
            " />
          <FieldInputNumber
            v-model="property.bus_to_nearest_station_3"
            label="最寄駅③までバス"
            color=""
            :error-message="errorObject.bus_to_nearest_station_3"
            @focusout="
              property.bus_to_nearest_station_3 = roundInteger(
                property.bus_to_nearest_station_3
              );
              removeEmptyErrorMessage(
                property.bus_to_nearest_station_3,
                'bus_to_nearest_station_3'
              );
              validateMax(
                property.bus_to_nearest_station_3,
                999,
                ERROR_MESSAGE['3'],
                'bus_to_nearest_station_3'
              );
            " />
          <FieldShortInput
            label1="区画最低価格"
            :value1="
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(property.block_price_min)
                ),
                {
                  suffix: '円万',
                  includeZero: true,
                }
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高価格"
            :value2="
              func.showValue(
                func.formatNumber(
                  func.convertAndRoundToMY(property.block_price_max)
                ),
                {
                  suffix: '円万',
                  includeZero: true,
                }
              )
            " />
          <FieldShortInput
            label1="区画最低間取り"
            :value1="
              func.convertEnum(
                property.block_layout_type_min,
                BLOCK_LAYOUT_TYPE
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高間取り"
            :value2="
              func.convertEnum(
                property.block_layout_type_max,
                BLOCK_LAYOUT_TYPE
              )
            " />
          <FieldShortInput
            label1="区画最低面積"
            :value1="
              func.showValue(
                property.block_area_min
                  ? func.formatNumber(property.block_area_min, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(property.block_area_min),
                {
                  suffix: '㎡',
                }
              )
            "
            color1=""
            color2=""
            :disable1="true"
            :disable2="true"
            label2="区画最高面積"
            :value2="
              func.showValue(
                property.block_area_max
                  ? func.formatNumber(property.block_area_max, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : func.formatNumber(property.block_area_max),
                {
                  suffix: '㎡',
                }
              )
            " />
          <FieldInputText
            v-model="property.structure_and_scale"
            label="構造・規模"
            max-length="99"
            :compulsory="!tli"
            :error-message="errorObject.structure_and_scale"
            @focus-out="
              removeEmptyErrorMessage(
                property.structure_and_scale,
                'structure_and_scale'
              )
            " />
          <FieldInputNumber
            v-model="property.number_of_houses"
            label="総戸数"
            :compulsory="!tli"
            :error-message="errorObject.number_of_houses"
            @focusout="
              property.number_of_houses = roundInteger(
                property.number_of_houses
              );
              removeEmptyErrorMessage(
                property.number_of_houses,
                'number_of_houses'
              );
              validateMax(
                property.number_of_houses,
                999,
                ERROR_MESSAGE['3'],
                'number_of_houses'
              );
            " />
          <FieldInputNumber
            v-model="property.number_of_floors"
            color=""
            label="階 建"
            :compulsory="!tli"
            :error-message="errorObject.number_of_floors"
            @focusout="
              property.number_of_floors = roundInteger(
                property.number_of_floors
              );
              removeEmptyErrorMessage(
                property.number_of_floors,
                'number_of_floors'
              );
              validateMax(
                property.number_of_floors,
                99,
                ERROR_MESSAGE['2'],
                'number_of_floors'
              );
            " />
          <FieldInputNumber
            v-model="property.site_area"
            label="敷地面積"
            :compulsory="!tli"
            :error-message="errorObject.site_area"
            @focusout="
              property.site_area = roundDecimal(property.site_area);
              removeEmptyErrorMessage(property.site_area, 'site_area');
              validateMax(
                property.site_area,
                99999.99,
                ERROR_MESSAGE['5'],
                'site_area'
              );
            " />
          <FieldInputText
            v-model="property.use_district"
            label="用途地域"
            max-length="99"
            :compulsory="!tli"
            :error-message="errorObject.use_district"
            @focusout="
              removeEmptyErrorMessage(property.use_district, 'use_district')
            " />
          <FieldInputText
            v-model="property.building_confirmation_number"
            label="建築確認番号"
            max-length="99"
            :compulsory="!tli"
            :error-message="errorObject.building_confirmation_number"
            @focusout="
              removeEmptyErrorMessage(
                property.building_confirmation_number,
                'building_confirmation_number'
              )
            " />
          <DateTimeInput
            ref="acquisition_date_of_building_confirmation"
            v-model="property.acquisition_date_of_building_confirmation"
            :compulsory="true"
            label="確認番号取得日"
            @error-message="
              (value) =>
                (errorObject['acquisition_date_of_building_confirmation'] =
                  value)
            " />
          <DateTimeInput
            ref="completion_date"
            v-model="property.completion_date"
            :compulsory="true"
            label="竣工日"
            @error-message="
              (value) => (errorObject['completion_date'] = value)
            " />
          <FieldInputText
            v-model="property.right_type"
            label="権利形態"
            max-length="99"
            :disabled="ifDisabled('right_type')"
            :compulsory="ifCompulsory('right_type')"
            :error-message="errorObject.right_type"
            @focus-out="
              removeEmptyErrorMessage(property.right_type, 'right_type')
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">分譲会社1</span>
              <span class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.seller_owner_1"
                  :class="{ 'field-error': errorObject.seller_owner_1 }"
                  label="分譲会社1"
                  :keyid="'sales_company_id'"
                  :keytext="'sales_company_name'"
                  :compulsory="true"
                  :options="dataSalesCompanies"
                  :clear="true"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.seller_owner_1,
                      'seller_owner_1'
                    )
                  " />
              </div>
              <p v-if="errorObject.seller_owner_1" class="error">
                {{ errorObject.seller_owner_1 }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">分譲会社2</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.seller_owner_2"
                  :class="{ 'field-error': errorObject.seller_owner_2 }"
                  label="分譲会社2"
                  :keyid="'sales_company_id'"
                  :keytext="'sales_company_name'"
                  :options="dataSalesCompanies"
                  :clear="true" />
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">分譲会社3</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.seller_owner_3"
                  :class="{ 'field-error': errorObject.seller_owner_3 }"
                  label="分譲会社3"
                  :keyid="'sales_company_id'"
                  :keytext="'sales_company_name'"
                  :options="dataSalesCompanies"
                  :clear="true" />
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">分譲会社4</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.seller_owner_4"
                  :class="{ 'field-error': errorObject.seller_owner_4 }"
                  label="分譲会社4"
                  :keyid="'sales_company_id'"
                  :keytext="'sales_company_name'"
                  :options="dataSalesCompanies"
                  :clear="true" />
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">分譲会社5</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.seller_owner_5"
                  :class="{ 'field-error': errorObject.seller_owner_5 }"
                  label="分譲会社5"
                  :keyid="'sales_company_id'"
                  :keytext="'sales_company_name'"
                  :options="dataSalesCompanies"
                  :clear="true" />
              </div>
            </div>
          </div>
          <FieldInputText
            v-model="property.construction_company"
            :compulsory="!tli"
            label="施工会社"
            max-length="99"
            :error-message="errorObject.construction_company"
            @focus-out="
              removeEmptyErrorMessage(
                property.construction_company,
                'construction_company'
              )
            " />
          <FieldInputText
            v-model="property.management_company"
            :compulsory="!tli"
            max-length="99"
            label="管理会社"
            :error-message="errorObject.management_company"
            @focus-out="
              removeEmptyErrorMessage(
                property.management_company,
                'management_company'
              )
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">管理形態</span>
              <span
                v-if="ifCompulsory('management_type')"
                class="badge badge-red"
                >必 須</span
              >
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.management_type"
                  :class="{ 'field-error': errorObject.management_type }"
                  label="管理形態"
                  :options="MANAGEMENT_TYPE"
                  :keyid="'value'"
                  :keytext="'label'"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.management_type,
                      'management_type'
                    )
                  " />
              </div>
              <p v-if="errorObject.management_type" class="error">
                {{ errorObject.management_type }}
              </p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">管理員の勤務形態</span>
              <span
                v-if="ifCompulsory('manager_working_style')"
                class="badge badge-red"
                >必 須</span
              >
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.manager_working_style"
                  :class="{ 'field-error': errorObject.manager_working_style }"
                  label="管理員の勤務形態"
                  :options="MANAGER_WORKING_STYLE"
                  :keyid="'value'"
                  :keytext="'label'"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.manager_working_style,
                      'manager_working_style'
                    )
                  " />
              </div>
              <p v-if="errorObject.manager_working_style" class="error">
                {{ errorObject.manager_working_style }}
              </p>
            </div>
          </div>
          <FieldInputNumber
            v-model="property.parking_lot_count"
            :compulsory="!tli"
            :error-message="errorObject.parking_lot_count"
            label="駐車場台数"
            @focus-out="
              property.parking_lot_count = roundInteger(
                property.parking_lot_count
              );
              removeEmptyErrorMessage(
                property.parking_lot_count,
                'parking_lot_count'
              );
              validateMax(
                property.parking_lot_count,
                9999,
                ERROR_MESSAGE['4'],
                'parking_lot_count'
              );
            " />
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">駐車場空き有無</span>
              <span
                v-if="ifCompulsory('parking_lot_status')"
                class="badge badge-red"
                >必 須</span
              >
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="custom-select2 w-300">
                <FieldSelect2
                  v-model="selectFields.parking_lot_status"
                  :class="{ 'field-error': errorObject.parking_lot_status }"
                  label="駐車場空き有無"
                  :options="PARKING_LOT_STATUS"
                  :keyid="'value'"
                  :keytext="'label'"
                  :change="
                    removeEmptyErrorMessage(
                      selectFields.parking_lot_status,
                      'parking_lot_status'
                    )
                  " />
              </div>
              <p v-if="errorObject.parking_lot_status" class="error">
                {{ errorObject.parking_lot_status }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">駐車場備考</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <textarea
                v-model="property.parking_lot_note"
                type="text"
                name=""
                maxlength="9999"
                class="form-control minh-120"></textarea>
            </div>
          </div>
          <FieldCheckBox
            :id="1"
            v-model="property.concierge_service"
            label="コンシェルジュサービス" />
          <FieldCheckBox
            :id="2"
            v-model="property.all_day_manned_management"
            label="24時間有人管理" />
          <FieldInputText
            v-model="property.trade_type"
            :compulsory="!tli"
            :error-message="errorObject.trade_type"
            max-length="99"
            label="取引形態"
            @focus-out="
              removeEmptyErrorMessage(property.trade_type, 'trade_type')
            " />
          <div class="row">
            <div class="col-12 col-md-3 my-2">
              <span class="form-title bg-blue-light">備 考</span>
            </div>
            <div class="col-12 col-md-9 my-2">
              <textarea
                v-model="property.property_note"
                type="text"
                name=""
                maxlength="9999"
                class="form-control minh-120"></textarea>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-3 my-2">
              <span v-if="!smallScreen" class="form-title"
                >レコメンド対象除外<br />フラグ</span
              >
              <span v-else class="form-title"
                >レコメンド対象<br />除外フラグ</span
              >
            </div>
            <div class="col-12 col-md-9 my-2">
              <div class="form-check custom-checkbox mx-3">
                <input
                  id="option1"
                  v-model="property.recommend_stop_flag"
                  class="form-check-input"
                  type="checkbox" />
                <label for="option1"></label>
              </div>
            </div>
          </div>
          <FieldInputText
            :model-value="convertDateTime(property?.created_at)"
            :disabled="true"
            color=""
            :label="`登録日時`" />
          <FieldInputText
            :model-value="convertDateTime(property?.updated_at)"
            :disabled="true"
            color=""
            :label="`更新日時`" />
          <FieldInputText
            :model-value="property?.updated_by_user?.name"
            :disabled="true"
            color=""
            :label="`更新者`" />
          <div class="row justify-content-end">
            <div class="col-12 col-md-9">
              <div class="my-4 text-center">
                <button
                  type="button"
                  class="btn btn-cus btn-blue-light m-2 ml-md-0 px-4 minw-100"
                  :disabled="!isFormChange"
                  @click="submitHandle">
                  {{ originalProperty ? "変 更" : "登 録" }}
                </button>
                <button
                  type="button"
                  class="btn btn-cus btn-grey m-2 w-150"
                  data-toggle="modal"
                  data-target="#existModal">
                  キャンセル
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getPrefectures, getCity, apiRailroad, getStation } from "./data.js";
import {
  roundDecimal,
  roundInteger,
  convertValueToString,
  scrollToTopElement,
} from "/src/components/utilitys/utility.js";
import { testImageInvalid } from "/src/service/properties.service.js";
import { getSalesCompanies } from "/src/service/sales-companies.service";
import DateTimeInput from "/src/components/utilitys/DateTimeInput.vue";
import {
  BLOCK_LAYOUT_TYPE,
  PARKING_LOT_STATUS,
  MANAGEMENT_TYPE,
  MANAGER_WORKING_STYLE,
  ORIGINAL_LOCATION,
} from "/src/constants";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import FieldSelect2Group from "/src/components/utilitys/select/FieldSelect2Group.vue";
import {
  FieldInputNumber,
  FieldInputText,
  FieldCheckBox,
  FieldShortInput,
  UploadImageFile,
} from "/src/components/properties/utility";
export default {
  components: {
    FieldSelect2,
    FieldSelect2Group,
    FieldInputNumber,
    FieldInputText,
    FieldShortInput,
    FieldCheckBox,
    UploadImageFile,
    DateTimeInput,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    originalProperty: {
      type: Object,
      default: () => null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    compulsoryList: {
      type: Array,
      default: () => [],
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
    selectList: {
      type: Array,
      default: () => [
        "seller_owner_1",
        "management_type",
        "manager_working_style",
        "parking_lot_status",
      ],
    },
    notCheckList: {
      type: Array,
      default: () => [
        "appearance_image_link",
        "guide_map_image_link",
        "location_prefecture",
        "location_district",
        "nearest_station_railway_1",
        "nearest_station_1 ",
        "completion_date",
      ],
    },
  },
  data() {
    let property = {
      appearance_image_link: null,
      guide_map_image_link: null,
      name: null,
      building_number: null,
      completion_date: null,
      walking_to_nearest_station_1: null,
      location_of_original_data: null,
      location_detail: null,
      block_price_min: null,
      block_price_max: null,
      block_layout_type_min: null,
      block_layout_type_max: null,
      block_area_min: null,
      block_area_max: null,
      structure_and_scale: null,
      number_of_houses: null,
      number_of_floors: null,
      site_area: null,
      use_district: null,
      building_confirmation_number: null,
      acquisition_date_of_building_confirmation: null,
      right_type: null,
      seller_owner_1: null,
      seller_owner_2: null,
      seller_owner_3: null,
      seller_owner_4: null,
      seller_owner_5: null,
      construction_company: null,
      management_company: null,
      management_type: null,
      manager_working_style: null,
      parking_lot_count: null,
      parking_lot_status: 0,
      parking_lot_note: null,
      concierge_service: false,
      all_day_manned_management: false,
      trade_type: null,
      property_note: null,
      recommend_stop_flag: false,
      created_at: null,
      updated_at: null,
      updated_by_user: null,
    };
    let selectFields = {
      seller_owner_1: null,
      seller_owner_2: null,
      seller_owner_3: null,
      seller_owner_4: null,
      seller_owner_5: null,
      management_type: null,
      manager_working_style: null,
      parking_lot_status: null,
    };
    return {
      property,
      selectFields,
      originalDataParams: null,
      dataParams: {
        appearance_image: "",
        guide_map_image: "",
        idPrefectures: {
          address_cd: 0,
        },
        idCity: 0,
        idRailroad: {
          reins_railroad_cd: 0,
        },
        idStation: 0,
        idRailroad2: {
          reins_railroad_cd: 0,
        },
        idStation2: 0,
        idRailroad3: {
          reins_railroad_cd: 0,
        },
        idStation3: 0,
      },
      apiRailroad,
      errorObject: {},
      dataPrefectures: [],
      dataRailroad: [],
      dataCity: [],
      dataStation: [],
      dataStation2: [],
      dataStation3: [],
      dataSalesCompanies: [],
      BLOCK_LAYOUT_TYPE,
      PARKING_LOT_STATUS,
      MANAGEMENT_TYPE,
      MANAGER_WORKING_STYLE,
      ERROR_MESSAGE: {
        1: "1桁の半角数字のみ入力可能です。",
        2: "2桁の半角数字のみ入力可能です。",
        4: "4桁の半角数字のみ入力可能です。",
        3: "3桁の半角数字のみ入力可能です。",
        5: "半角数字と小数点のみ入力可能です。(整数5桁、小数点2桁)",
        6: "6桁の半角数字と小数点のみ入力可能です。",
        REQUIRED: "入力項目に不足、または不備があります。",
      },
    };
  },
  computed: {
    smallScreen() {
      return window.innerWidth < 850;
    },
    isFormChange() {
      if (this.originalProperty) {
        // Compare property
        for (let key in this.property) {
          if (
            this.compareEqual(this.property[key], this.originalProperty[key])
          ) {
            return true;
          }
        }
        // Compare selectFields
        for (let key in this.selectFields) {
          if (key.includes("seller_owner")) {
            if (
              this.selectFields[key] !==
              convertValueToString(
                this.originalProperty[`${key}_`]?.sales_company_id
              )
            ) {
              return true;
            }
          } else {
            if (
              this.selectFields[key] !==
              convertValueToString(this.originalProperty[key])
            ) {
              return true;
            }
          }
        }
        // Compare image change
        if (
          this.dataParams.appearance_image !== "" ||
          this.dataParams.guide_map_image !== ""
        ) {
          return true;
        }
        if (
          JSON.stringify(this.dataParams) !==
          JSON.stringify(this.originalDataParams)
        ) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    tli() {
      return (
        this.property.location_of_original_data === ORIGINAL_LOCATION.DYNAMIC
      );
    },
  },
  watch: {
    "dataPrefectures.length": {
      handler(newLength) {
        if (newLength > 0 && this.originalProperty) {
          if (this.originalProperty.location_prefecture) {
            this.dataParams.idPrefectures.address_cd = this.findOutAndGetField(
              this.originalProperty.location_prefecture,
              this.dataPrefectures,
              "prefectures_name",
              "prefectures_cd"
            );
            this.selectPrefecture();
          }
          this.originalDataParams = this.deepCopy(this.dataParams);
        } else if (newLength > 0) {
          this.dataParams.idPrefectures.address_cd = 13;
          this.selectPrefecture();
        }
      },
    },
    "dataParams.idRailroad2.reins_railroad_cd": {
      handler(newVal) {
        if (newVal === null) {
          this.dataParams.idRailroad2.reins_railroad_cd = 0;
          this.dataParams.idStation2 = 0;
          this.property.walking_to_nearest_station_2 = "";
          this.property.bus_to_nearest_station_2 = "";
        }
      },
    },
    "dataParams.idRailroad3.reins_railroad_cd": {
      handler(newVal) {
        if (newVal === null) {
          this.dataParams.idRailroad3.reins_railroad_cd = 0;
          this.dataParams.idStation3 = 0;
          this.property.walking_to_nearest_station_3 = "";
          this.property.bus_to_nearest_station_3 = "";
        }
      },
    },
  },
  async created() {
    // Assign data to property
    if (this.originalProperty) {
      this.property = { ...this.originalProperty };
      if (this.property["site_area"]) {
        this.property["site_area"] = roundDecimal(this.property["site_area"]);
      }
      for (let key in this.selectFields) {
        if (key.includes("seller_owner")) {
          this.selectFields[key] = convertValueToString(
            this.originalProperty?.[`${key}_`]?.sales_company_id
          );
        } else {
          this.selectFields[key] = convertValueToString(
            this.originalProperty[key]
          );
        }
      }
    }
    this.getSalesCompanies();
    this.checkHost().then((res) => {
      this.convertLocationValueFromBEToFE();
    });
  },
  methods: {
    compareEqual(value1, value2) {
      if (typeof value1 === "string" && value1 == "") {
        value1 = null;
      }
      if (typeof value2 === "string" && value2 == "") {
        value2 = null;
      }
      return value1 != value2;
    },
    deepCopy(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    convertLocationValueFromBEToFE() {
      // convert idPrefectures, idRailroad, idRailroad2, idRailroad3 from text -> number
      if (this.originalProperty) {
        this.dataParams.idCity = this.originalProperty.location_district;
        if (this.originalProperty.nearest_station_railway_1) {
          this.dataParams.idRailroad.reins_railroad_cd =
            this.findOutAndGetField(
              this.originalProperty.nearest_station_railway_1,
              this.dataRailroad,
              "name",
              "reins_railroad_cd",
              true
            );
          this.selectRailroad();
          this.dataParams.idStation = this.originalProperty.nearest_station_1;
        }
        if (this.originalProperty.nearest_station_railway_2) {
          this.dataParams.idRailroad2.reins_railroad_cd =
            this.findOutAndGetField(
              this.originalProperty.nearest_station_railway_2,
              this.dataRailroad,
              "name",
              "reins_railroad_cd",
              true
            );
          this.selectRailroad2();
          this.dataParams.idStation2 = this.originalProperty.nearest_station_2;
        }
        if (this.originalProperty.nearest_station_railway_3) {
          this.dataParams.idRailroad3.reins_railroad_cd =
            this.findOutAndGetField(
              this.originalProperty.nearest_station_railway_3,
              this.dataRailroad,
              "name",
              "reins_railroad_cd",
              true
            );
          this.selectRailroad3();
          this.dataParams.idStation3 = this.originalProperty.nearest_station_3;
        }
        this.originalDataParams = this.deepCopy(this.dataParams);
      }
    },
    convertLocationValueFromFEToBE() {
      // convert idPrefectures, idRailroad, idRailroad2, idRailroad3 from number -> text
      this.property.location_prefecture = this.findOutAndGetField(
        this.dataParams.idPrefectures.address_cd,
        this.dataPrefectures,
        "prefectures_cd",
        "prefectures_name"
      );
      this.property.location_district = this.dataParams.idCity;
      // Station 1
      if (
        this.dataParams.idRailroad.reins_railroad_cd === undefined &&
        this.originalProperty &&
        this.originalProperty.nearest_station_railway_1
      ) {
        this.property.nearest_station_railway_1 =
          this.originalProperty.nearest_station_railway_1;
      } else {
        this.property.nearest_station_railway_1 = this.findOutAndGetField(
          this.dataParams.idRailroad.reins_railroad_cd,
          this.dataRailroad,
          "reins_railroad_cd",
          "name",
          true
        );
      }
      this.property.nearest_station_1 = this.dataParams.idStation;
      // Station 2
      if (
        this.dataParams.idRailroad2.reins_railroad_cd === undefined &&
        this.originalProperty &&
        this.originalProperty.nearest_station_railway_2
      ) {
        this.property.nearest_station_railway_2 =
          this.originalProperty.nearest_station_railway_2;
      } else {
        this.property.nearest_station_railway_2 = this.findOutAndGetField(
          this.dataParams.idRailroad2.reins_railroad_cd,
          this.dataRailroad,
          "reins_railroad_cd",
          "name",
          true
        );
      }
      this.property.nearest_station_2 =
        this.dataParams.idStation2 == 0 ? null : this.dataParams.idStation2;
      // Station 3
      if (
        this.dataParams.idRailroad3.reins_railroad_cd === undefined &&
        this.originalProperty &&
        this.originalProperty.nearest_station_railway_3
      ) {
        this.property.nearest_station_railway_3 =
          this.originalProperty.nearest_station_railway_3;
      } else {
        this.property.nearest_station_railway_3 = this.findOutAndGetField(
          this.dataParams.idRailroad3.reins_railroad_cd,
          this.dataRailroad,
          "reins_railroad_cd",
          "name",
          true
        );
      }
      this.property.nearest_station_3 =
        this.dataParams.idStation3 == 0 ? null : this.dataParams.idStation3;
    },
    convertDateTime(date) {
      // increase space between date and time
      if (!date) {
        return "";
      }
      let dateTimeArray = this.func
        .formatDateToParts(date, {
          dateStyle: "long",
          timeStyle: "short",
        })
        .map((item) => item.value);
      if (parseInt(dateTimeArray[2]) < 10) {
        dateTimeArray[2] = "0" + dateTimeArray[2];
      }
      if (parseInt(dateTimeArray[4]) < 10) {
        dateTimeArray[4] = "0" + dateTimeArray[4];
      }
      if (parseInt(dateTimeArray[6]) < 10) {
        dateTimeArray[6] = "0" + dateTimeArray[6];
      }
      return (
        dateTimeArray.slice(0, 6).join("") +
        "   " +
        dateTimeArray.slice(6).join("")
      );
    },
    roundInteger(value) {
      return roundInteger(value);
    },
    roundDecimal(value) {
      return roundDecimal(value);
    },
    ifDisabled(name) {
      return this.disabledList.includes(name);
    },
    ifCompulsory(name) {
      return this.compulsoryList.includes(name);
    },
    // VALIDATE
    validateMax(
      value,
      max,
      errorMessage,
      field,
      errorObject = this.errorObject
    ) {
      if (value > max) {
        errorObject[field] = errorMessage;
      } else if (errorObject[field] == errorMessage) {
        errorObject[field] = "";
      }
    },
    validateRequired(value) {
      if ([undefined, null, ""].includes(value)) {
        return false;
      }
      return true;
    },
    removeEmptyErrorMessage(value, field, errorObject = this.errorObject) {
      if (
        ["idPrefectures", "idCity", "idRailroad", "idStation"].includes(field)
      ) {
        if (value != 0) {
          errorObject[field] = "";
        }
        return;
      }
      if (this.validateRequired(value)) {
        if (errorObject[field] == this.ERROR_MESSAGE.REQUIRED) {
          errorObject[field] = "";
        }
      }
    },
    validateRailroadStation() {
      let flagRail1, flagRail2, flagRail3, flagCity;
      // Check if idStation1 is in dataStation
      for (let i = 0; i < this.dataStation.length; i++) {
        if (this.dataStation[i].station_name == this.dataParams.idStation) {
          flagRail1 = true;
          break;
        }
      }
      // Check if idStation2 is in dataStation2
      for (let i = 0; i < this.dataStation2.length; i++) {
        if (this.dataStation2[i].station_name == this.dataParams.idStation2) {
          flagRail2 = true;
          break;
        }
      }
      // Check if idStation3 is in dataStation3
      for (let i = 0; i < this.dataStation3.length; i++) {
        if (this.dataStation3[i].station_name == this.dataParams.idStation3) {
          flagRail3 = true;
          break;
        }
      }
      for (let i = 0; i < this.dataCity.length; i++) {
        if (this.dataCity[i].city_name == this.dataParams.idCity) {
          flagCity = true;
          break;
        }
      }
      if (flagRail1 !== true) {
        this.dataParams.idStation = 0;
      }
      if (flagRail2 !== true) {
        this.dataParams.idStation2 = 0;
      }
      if (flagRail3 !== true) {
        this.dataParams.idStation3 = 0;
      }
      if (flagCity !== true) {
        this.dataParams.idCity = 0;
      }
    },
    async validateForm() {
      // Validate empty of fields
      this.validateRailroadStation();
      this.compulsoryList.forEach((field) => {
        if (this.notCheckList.includes(field)) {
          // pass
        } else if (this.selectList.includes(field)) {
          if (!this.validateRequired(this.selectFields[field])) {
            this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
          }
        } else if (!this.validateRequired(this.property[field])) {
          this.errorObject[field] = this.ERROR_MESSAGE.REQUIRED;
        }
      });
      if (this.dataParams.idPrefectures.address_cd == 0) {
        this.errorObject["idPrefectures"] = this.ERROR_MESSAGE.REQUIRED;
      }
      if (this.dataParams.idCity == 0) {
        this.errorObject["idCity"] = this.ERROR_MESSAGE.REQUIRED;
      }
      if (this.dataParams.idRailroad.reins_railroad_cd == 0) {
        this.errorObject["idRailroad"] = this.ERROR_MESSAGE.REQUIRED;
      }
      if (this.dataParams.idStation == 0) {
        this.errorObject["idStation"] = this.ERROR_MESSAGE.REQUIRED;
      }
      // Validate images
      if (
        !(
          this.dataParams.appearance_image ||
          this.property.appearance_image_link
        )
      ) {
        this.errorObject["appearance_image"] = this.ERROR_MESSAGE.REQUIRED;
      }
      if (
        !(this.dataParams.guide_map_image || this.property.guide_map_image_link)
      ) {
        this.errorObject["guide_map_image"] = this.ERROR_MESSAGE.REQUIRED;
      }
      // Validate date
      this.$refs.acquisition_date_of_building_confirmation.validateDateEmpty();
      this.$refs.completion_date.validateDateEmpty();
      // this.validateDateEmpty();
      for (let key in this.completion_date_error) {
        if (this.completion_date_error[key] != "") {
          return false;
        }
      }
      // Validate Image with BE
      if (this.dataParams.appearance_image) {
        let errorMessage = await testImageInvalid(
          this.dataParams.appearance_image
        );
        if (errorMessage) {
          this.errorObject.appearance_image = errorMessage;
        } else {
          this.errorObject.appearance_image = "";
        }
      }
      if (this.dataParams.guide_map_image) {
        let errorMessage = await testImageInvalid(
          this.dataParams.guide_map_image
        );
        if (errorMessage) {
          this.errorObject.guide_map_image = errorMessage;
        } else {
          this.errorObject.guide_map_image = "";
        }
      }
      // End validate Image with BE
      for (let key in this.errorObject) {
        if (this.errorObject[key] != "") {
          return false;
        }
      }
      return true;
    },
    // END VALIDATE
    async getSalesCompanies() {
      getSalesCompanies().then((res) => {
        this.dataSalesCompanies = res.data.items;
      });
    },
    setData(data) {
      this.property = data;
    },
    // START API Prefectures and City and Railroad and Station
    async checkHost() {
      this.dataRailroad = this.apiRailroad;
      let sv = location.host.split(".")[0].split("-");
      if (sv.includes("dev")) {
        return this.getPrefect("apiDev");
      } else {
        return this.getPrefect("apiProd");
      }
    },
    async getPrefect(api) {
      const response = await getPrefectures(api);
      this.dataPrefectures = response.data.data;
      return response.data.data;
    },
    async selectPrefecture() {
      let response = await getCity(this.dataParams.idPrefectures);
      this.dataCity = response.data.data;
    },
    async selectRailroad() {
      let response = await getStation(this.dataParams.idRailroad);
      this.dataStation = response.data.data;
      if (
        this.dataStation.length == 0 &&
        this.originalProperty.nearest_station_1
      ) {
        this.dataStation.push({
          station_name: this.originalProperty.nearest_station_1,
        });
      }
    },
    async selectRailroad2() {
      let response = await getStation(this.dataParams.idRailroad2);
      this.dataStation2 = response.data.data;
      if (
        this.dataStation2.length == 0 &&
        this.originalProperty.nearest_station_2
      ) {
        this.dataStation2.push({
          station_name: this.originalProperty.nearest_station_2,
        });
      }
    },
    async selectRailroad3() {
      let response = await getStation(this.dataParams.idRailroad3);
      this.dataStation3 = response.data.data;
      if (
        this.dataStation3.length == 0 &&
        this.originalProperty.nearest_station_3
      ) {
        this.dataStation3.push({
          station_name: this.originalProperty.nearest_station_3,
        });
      }
    },
    // END API Prefectures and City and Railroad and Station
    // START Submit functions
    mergeData() {
      this.convertLocationValueFromFEToBE();
      let property = { ...this.property, ...this.selectFields };
      // clean data
      for (let key in property) {
        if (property[key] === "") {
          property[key] = null;
        }
      }
      return property;
    },
    findOutAndGetField(value, data, key_search, key_result, nested = false) {
      let result = null;
      if (nested) {
        for (let item of data) {
          result = item.list.find((item) => item[key_search] == value);
          if (result) {
            break;
          }
        }
      } else {
        result = data.find((item) => item[key_search] == value);
      }
      if (!result) {
        console.log(`${value} not in ${key_search} of ${data}`);
      }
      return result ? result[key_result] : undefined;
    },
    async submitHandle() {
      if (await this.validateForm()) {
        this.$emit("submitHandle", {
          property: this.mergeData(),
          appearance_image: this.dataParams.appearance_image,
          guide_map_image: this.dataParams.guide_map_image,
        });
      } else {
        scrollToTopElement([".error"]);
      }
    },
    // END Submit functions
  },
};
</script>
