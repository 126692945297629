<template>
  <div class="row align-items-center" :class="{ 'mb-4': error && !disabled }">
    <!-- Checkbox and Radio -->
    <div class="col-md-12 col-xl-8 text-nowrap">
      <div class="row align-items-center">
        <!-- checkbox property select -->
        <div class="col-sm-12 col-md-3 my-2 pr-0">
          <div class="form-check custom-checkbox d-inline-block">
            <input
              :id="`property-${idx}`"
              v-model="property.checked"
              class="form-check-input"
              type="checkbox"
              :disabled="disabled" />
            <label :for="`property-${idx}`">紹介物件{{ idx }}</label>
          </div>
        </div>

        <!-- location of original data -->
        <div class="col-sm-12 col-md-3 my-2">
          <div class="form-check custom-checkbox">
            <input
              :id="`type_dynamic_${idx}`"
              v-model="property.location_of_original_data"
              class="form-check-input"
              type="radio"
              :name="`location_${idx}`"
              :value="assetType.DYNAMIC"
              :disabled="isDisable" />
            <label :for="`type_dynamic_${idx}`">新 築</label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 my-2">
          <div class="form-check custom-checkbox">
            <input
              :id="`type_other_${idx}`"
              v-model="property.location_of_original_data"
              class="form-check-input"
              type="radio"
              :name="`location_${idx}`"
              :value="assetType.OTHER"
              :disabled="isDisable" />
            <label :for="`type_other_${idx}`">他社媒介/新築</label>
          </div>
        </div>
        <div class="col-sm-12 col-md-2 my-2">
          <div class="form-check custom-checkbox">
            <input
              :id="`type_salesforce_${idx}`"
              v-model="property.location_of_original_data"
              class="form-check-input"
              type="radio"
              :name="`location_${idx}`"
              :value="assetType.SALESFORCE"
              :disabled="isDisable" />
            <label :for="`type_salesforce_${idx}`">中 古</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Property -->
    <div class="col-md-12 col-xl-4">
      <div class="row align-items-center">
        <div class="col-12 col-md-3 text-sm-left text-md-right my-2">
          <span v-show="isRequire" class="badge badge-red ml-0">必 須</span>
        </div>
        <div class="col-12 col-md-9 my-2">
          <!-- Select property ID -->
          <SimpleSelect2
            v-show="!isSaleForce"
            :key="`select_prop_${property.property_id}_${properties.length}`"
            v-model.number="property.property_id"
            class="custom-select2 w-100 selectBox"
            :class="{ 'field-error': error }"
            :long-options="properties"
            :disabled="isDisable"
            @update:modelValue="$emit('clear-error')" />

          <!-- Input property Name -->
          <input
            v-show="isSaleForce"
            v-model.trim="property.name"
            type="text"
            maxlength="99"
            class="form-control textBox"
            :class="{ 'field-error': error }"
            :disabled="isDisable"
            @focusout="$emit('focusout')"
            @input="$emit('clear-error')" />
        </div>
      </div>
      <div v-show="error && !disabled" class="row error-container">
        <div class="col-12 col-md-9">
          <p class="error">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";

import SimpleSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";

export default {
  components: {
    SimpleSelect2,
  },
  props: {
    modelValue: { type: Object, required: true },
    idx: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
    dynamicProperties: { type: Array, default: () => [] },
    otherProperties: { type: Array, default: () => [] },
    error: { type: String, default: "" },
  },
  emits: ["update:modelValue", "focusout", "clear-error"],
  data() {
    return {
      assetType: ORIGINAL_LOCATION,
    };
  },
  computed: {
    property: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    properties() {
      const isViewOnly =
        this.disabled &&
        this.dynamicProperties.length === 0 &&
        this.otherProperties.length === 0;
      if (isViewOnly)
        return [
          { value: this.property.property_id, label: this.property.name },
        ];
      const loc = this.property.location_of_original_data;
      if (loc == ORIGINAL_LOCATION.DYNAMIC) return this.dynamicProperties;
      if (loc == ORIGINAL_LOCATION.OTHER) return this.otherProperties;
      return [];
    },
    isSaleForce() {
      return (
        this.property.location_of_original_data == ORIGINAL_LOCATION.SALESFORCE
      );
    },
    isDisable() {
      return this.disabled;
    },
    isRequire() {
      return !this.disabled && this.property.checked;
    },
  },
  watch: {
    "property.location_of_original_data"() {
      this.resetPropertyId();
      this.$emit("clear-error");
    },
    "property.checked"() {
      this.$emit("clear-error");
    },
  },
  methods: {
    resetPropertyId() {
      const newValue = { ...this.property };
      newValue.property_id = null;
      newValue.name = null;
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/action-form-extra.css";
</style>
