<template>
  <BaseLayout>
    <FormLayout :title="title">
      <CustomerName :customer-id="customerId" :customer-name="customerName" />
      <SelectTime
        v-model="dataParam.follow_date"
        :error="errorMsg.follow_date"
        :disabled="viewOnly"
        @update:modelValue="checkAndHideError('follow_date', 'follow_date')" />
      <SelectUser
        v-model="dataParam.user_id"
        :error="errorMsg.user_id"
        :disabled="viewOnly"
        :fetch-delay="fetchDelay"
        @update:modelValue="checkAndHideError('user_id', 'user_id')" />

      <div class="row align-items-center">
        <!-- Select Contact Method -->
        <div class="col-md-12 col-xl-6">
          <div class="row align-items-center">
            <div class="col-12 col-md-4 col-xl-6 my-2">
              接触方法
              <span v-show="!viewOnly" class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-5 col-xl-6 my-2">
              <SimpleSelect2
                v-model="dataParam.contact_method"
                class="custom-select2 w-100"
                :class="{ 'field-error': errorMsg.contact_method }"
                :options="contactMethods"
                :disabled="viewOnly"
                @update:modelValue="
                  checkAndHideError('contact_method', 'contact_method')
                " />
            </div>
          </div>
          <div
            v-show="errorMsg.contact_method || errorMsg.contact_result"
            class="row align-items-center">
            <div class="col-12 col-md-4 col-xl-6 my-2"></div>
            <div class="col">
              <p class="error">{{ errorMsg.contact_method }}</p>
            </div>
          </div>
        </div>
        <!-- Select Contact Result -->
        <div class="col-md-12 col-xl-6">
          <div class="row align-items-center">
            <div class="col-12 col-md-4 col-xl-6 my-2 text-xl-right">
              接触結果
              <span v-show="!viewOnly" class="badge badge-red">必 須</span>
            </div>
            <div class="col-12 col-md-5 col-xl-6 my-2">
              <SimpleSelect2
                v-model.number="dataParam.contact_result"
                class="custom-select2 w-100"
                :class="{ 'field-error': errorMsg.contact_result }"
                :long-options="contactResults"
                :disabled="viewOnly"
                @update:modelValue="
                  checkAndHideError('contact_result', 'contact_result')
                " />
            </div>
          </div>
          <div
            v-show="errorMsg.contact_method || errorMsg.contact_result"
            class="row align-items-center">
            <div class="col-12 col-md-4 col-xl-6 my-2 text-xl-right"></div>
            <div class="col">
              <p class="error">{{ errorMsg.contact_result }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center checkboxJs">
        <div class="col-12 my-3">
          <div class="form-check custom-checkbox d-inline-block">
            <input
              id="no-introduction-property"
              v-model="noRecommend"
              class="form-check-input"
              type="checkbox"
              :disabled="viewOnly"
              @change="uncheckAll()" />
            <label for="no-introduction-property">紹介物件なし</label>
          </div>
        </div>
      </div>

      <RecommendProperty
        v-for="i in 5"
        :key="`prop_${i}`"
        v-model="dataParam[`property_${i}`]"
        :dynamic-properties="dynamicProperties"
        :other-properties="otherProperties"
        :idx="i"
        :disabled="viewOnly || noRecommend"
        :error="errorMsg[`property_${i}`]"
        @clear-error="errorMsg[`property_${i}`] = ''"
        @focusout="normalizePropertyName(`property_${i}`)" />

      <div class="row align-items-center">
        <div class="col-12 my-2">
          <div class="mb-2">コメント</div>
          <textarea
            v-model.trim="dataParam.comment"
            maxlength="9999"
            rows="5"
            class="form-control"
            :disabled="viewOnly"
            @input="
              if (isBlank(dataParam.comment)) dataParam.comment = null;
            "></textarea>
        </div>
      </div>

      <!-- Exit button -->
      <div v-if="viewOnly" class="row">
        <div class="col-12">
          <div class="my-4 text-center">
            <button
              type="button"
              class="btn btn-cus btn-blue-light px-3 minw-100"
              @click="handleConfirm">
              <span>戻 る</span>
            </button>
          </div>
        </div>
      </div>
      <!-- Modal toggle buttons -->
      <div v-else class="row">
        <div class="col-12">
          <div class="my-4 text-center">
            <button
              v-if="$route.name === 'add-sales-follow'"
              type="button"
              class="btn btn-cus btn-blue-light m-2 w-150"
              @click="handleBtnSubmit">
              <span>登 録</span>
            </button>
            <button
              v-if="$route.name === 'update-sales-follow'"
              type="button"
              class="btn btn-cus btn-blue-light m-2 w-150"
              :disabled="!isChanged"
              @click="isChanged && handleBtnSubmit()">
              <span>変 更</span>
            </button>
            <button
              type="button"
              class="btn btn-cus btn-grey m-2 w-150"
              @click="handleBtnCancel">
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </FormLayout>
    <ModalConfirm
      v-if="!viewOnly"
      id="modal-confirm"
      :message="modalMsg[clickedButton]"
      @no="showClosedMessage"
      @yes="handleConfirm" />
  </BaseLayout>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { textNormalize } from "/src/service/users.service.js";
import { contactMethods, contactResults } from "/src/helpers/select-options.js";
const { userMessage, apiMessage } = require("/src/assets/validation.json");
const errorBlankMessage = userMessage.REQUIRED;

import SimpleSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import FormLayout from "./FormLayout.vue";
import CustomerName from "./CustomerName.vue";
import SelectTime from "./SelectTime.vue";
import SelectUser from "/src/components/customers/action/form-fields/SelectUser.vue";
import RecommendProperty from "./RecommendProperty.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";

import $ from "jquery";

export default {
  components: {
    BaseLayout,
    FormLayout,
    SimpleSelect2,
    CustomerName,
    SelectTime,
    SelectUser,
    RecommendProperty,
    ModalConfirm,
  },
  props: {
    customerId: { type: Number, default: null },
    customerName: { type: String, default: null },
    modalMsg: { type: Object, default: null },
    dynamicProperties: { type: Array, default: () => [] },
    otherProperties: { type: Array, default: () => [] },
    initFormData: { type: Object, required: true },
    title: { type: String, required: true },
    fetchDelay: { type: Number, default: 0 },
  },
  emits: ["confirm-submit", "confirm-cancel"],
  data() {
    return {
      dataParam: {
        follow_date: this.initFormData.follow_date,
        user_id: this.initFormData.user_id,
        contact_method: this.initFormData.contact_method,
        contact_result: this.initFormData.contact_result,
        property_1: this.initFormData.property_1,
        property_2: this.initFormData.property_2,
        property_3: this.initFormData.property_3,
        property_4: this.initFormData.property_4,
        property_5: this.initFormData.property_5,
        comment: this.initFormData.comment,
      },
      errorMsg: {
        follow_date: "",
        user_id: "",
        contact_method: "",
        contact_result: "",
        property_1: "",
        property_2: "",
        property_3: "",
        property_4: "",
        property_5: "",
      },
      contactMethods: contactMethods,
      contactResults: contactResults,
      noRecommend: false,
      clickedButton: "cancel", // "submit" or "cancel"
      original: null,
    };
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    viewOnly() {
      return this.$route.name === "detail-sales-follow";
    },
    isChanged() {
      if (this.isLoading) return false;
      const curr = JSON.parse(JSON.stringify(this.dataParam));
      for (const key in curr) {
        if (!_.isEqual(this.original[key], curr[key])) return true;
      }
      return this.original.noRecommend !== this.noRecommend;
    },
    commonPayload() {
      return {
        follow_date: this.dataParam.follow_date,
        user_id: this.dataParam.user_id,
        contact_method: this.dataParam.contact_method,
        contact_result: this.dataParam.contact_result,
        comment: this.dataParam.comment,
      };
    },
  },
  watch: {
    isLoading(newVal) {
      // Store original data when page are loaded
      if (!newVal) {
        this.original = JSON.parse(JSON.stringify(this.dataParam));
        this.original.noRecommend = this.noRecommend;
      }
    },
  },
  created() {
    this.noRecommend = this.checkNoRecommend();
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    showClosedMessage() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    uncheckAll() {
      for (let i = 1; i <= 5; i++)
        this.dataParam[`property_${i}`].checked = false;
    },
    checkNoRecommend() {
      if (this.$route.name === "add-sales-follow") return false;
      for (let i = 1; i <= 5; i++) {
        const checked = this.dataParam[`property_${i}`]?.checked;
        if (checked) return false;
      }
      return true;
    },
    isBlank(value) {
      return value === null || value === "" || value === undefined;
    },
    validateField(dataKey, errorKey) {
      if (this.isBlank(this.dataParam[dataKey])) {
        this.errorMsg[errorKey] = errorBlankMessage;
        return false;
      }
      this.errorMsg[errorKey] = "";
      return true;
    },
    checkAndHideError(dataKey, errorKey) {
      if (!this.isBlank(this.dataParam[dataKey])) this.errorMsg[errorKey] = "";
    },
    normalizePropertyName(dataKey) {
      if (this.dataParam[dataKey]?.name)
        this.dataParam[dataKey].name = textNormalize(
          this.dataParam[dataKey].name
        );
    },
    validateProperties() {
      if (this.noRecommend) return true;
      let isValid = true;
      for (let i = 1; i <= 5; i++) {
        const key = `property_${i}`;
        const property = this.dataParam[key];
        if (
          property.checked &&
          this.isBlank(property.name) &&
          this.isBlank(property.property_id)
        ) {
          this.errorMsg[key] = errorBlankMessage;
          isValid = false;
        }
      }
      return isValid;
    },
    formValidate() {
      let isValid = true;
      for (const key in this.errorMsg) {
        if (!this.validateField(key, key)) isValid = false;
      }
      return this.validateProperties() && isValid;
    },
    handleBtnSubmit() {
      this.clickedButton = "submit";
      if (this.formValidate()) $(`#modal-confirm`).modal("show");
    },
    handleBtnCancel() {
      this.clickedButton = "cancel";
      $(`#modal-confirm`).modal("show");
    },
    handleConfirm() {
      if (this.clickedButton === "cancel") this.$emit("confirm-cancel");

      if (this.clickedButton === "submit") {
        const body =
          this.$route.name === "update-sales-follow"
            ? this.updatePayload()
            : this.createPayload();

        this.$emit("confirm-submit", body);
      }
    },
    createPayload() {
      const body = { ...this.commonPayload };
      // Only emit checked property
      if (!this.noRecommend) {
        for (let i = 1; i <= 5; i++) {
          const key = `property_${i}`;
          const property = this.dataParam[key];
          if (property.checked && (property.name || property.property_id))
            body[key] = property;
        }
      }
      return body;
    },
    updatePayload() {
      const body = { ...this.commonPayload };
      for (let i = 1; i <= 5; i++) {
        const key = `property_${i}`;
        const property = this.dataParam[key];
        // Update to null if property is not selected
        if (!property.checked) {
          property.location_of_original_data = null;
          property.property_id = null;
          property.name = null;
        }
        body[key] = property;
      }
      return body;
    },
  },
};
</script>
