<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <router-view></router-view>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SideBarLeft from "../../components/layouts/SidebarLeft.vue";
import SideBarTop from "../../components/layouts/SidebarTop.vue";

export default {
  name: "UserView",
  components: {
    SideBarLeft,
    SideBarTop,
  },
};
</script>
