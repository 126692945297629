<template>
  <div>
    <TabTableLayout
      v-model="customerActionHistory"
      table-label="来場・送客履歴"
      :nav-button="{ text: '送客履歴登録', routeParam: paramTLI07004 }"
      :fetch-func="fetchActionHistories()"
      :fetch-delay="API_DELAY">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="w-215">来場日</th>
          <th class="w-300">担 当</th>
          <th>物件名</th>
          <th>区画名</th>
          <th class="w-84">編 集</th>
          <th class="w-84">削 除</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in customerActionHistory.items"
          :key="`tr_action_${index}`">
          <td class="text-nowrap text-right">
            {{ formatDate(item.action_date) }}
          </td>
          <td>{{ func.displayValue(item.user?.name) }}</td>
          <td>
            {{ func.displayValue(item.property_name) }}
          </td>
          <td class="text-center">
            {{ func.displayValue(item.block_name) }}
          </td>
          <td class="text-center">
            <FunctionalButton
              class="btn-blue-light"
              :is-valid="canEditActionHistory(item)"
              @click="navigateToEditCustomerAction(item)">
              <i class="fas fa-pen"></i>
            </FunctionalButton>
          </td>
          <td class="text-center">
            <FunctionalButton
              class="btn-pink btn-icon"
              :is-valid="canEditActionHistory(item)"
              @click="deleteActionHistory(item)">
              <i class="fas fa-trash"></i>
            </FunctionalButton>
          </td>
        </tr>
      </tbody>
    </TabTableLayout>

    <TabTableLayout
      v-model="closedProperty"
      table-label="成約履歴"
      :nav-button="{ text: '成約登録', routeParam: paramTLI07006 }"
      :fetch-func="fetchClosedProperties()"
      :fetch-delay="2 * API_DELAY">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="w-215">成約日</th>
          <th class="w-215">引渡予定日</th>
          <th>担 当</th>
          <th>物件名</th>
          <th>区画名</th>
          <th class="w-84">編 集</th>
          <th class="w-84">削 除</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in closedProperty.items"
          :key="`tr_closed_${index}`">
          <td class="text-nowrap text-right">
            {{ formatDate(item.closing_date) }}
          </td>
          <td class="text-nowrap text-right">
            {{ formatDate(item.delivery_date) }}
          </td>
          <td>{{ func.displayValue(item.user?.name) }}</td>
          <td>{{ func.displayValue(item.property_name) }}</td>
          <td class="text-center">{{ func.displayValue(item.block_name) }}</td>
          <td class="text-center">
            <FunctionalButton
              class="btn-blue-light"
              :is-valid="canEditClosedProperty(item)"
              @click="navigateToEditClosedProperty(item)">
              <i class="fas fa-pen"></i>
            </FunctionalButton>
          </td>
          <td class="text-center">
            <FunctionalButton
              class="btn-pink btn-icon"
              :is-valid="canEditClosedProperty(item)"
              @click="deleteClosedProperty(item)">
              <i class="fas fa-trash"></i>
            </FunctionalButton>
          </td>
        </tr>
      </tbody>
    </TabTableLayout>
  </div>
</template>

<script>
import { API_DELAY } from "/src/frontend/src/constants.js";
import { getActionHistories } from "/src/service/customer-action-histories.service.js";
import { getClosedProperties } from "/src/service/closed-properties.service.js";
import { isValidItem } from "./utils.js";

import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";
import TabTableLayout from "./TabTableLayout.vue";

export default {
  components: {
    FunctionalButton,
    TabTableLayout,
  },
  data() {
    return {
      customerActionHistory: {},
      closedProperty: {},
      paramTLI07004: { name: "history-registry" },
      paramTLI07005: { name: "history-update", params: { history_id: null } },
      paramTLI07006: { name: "contract-registry" },
      paramTLI07007: {
        name: "contract-update",
        params: { closed_property_id: null },
      },
      API_DELAY,
    };
  },
  methods: {
    formatDate(dateStr) {
      return this.func.formatDate(dateStr, { dateStyle: "long" });
    },
    fetchActionHistories() {
      return (params) =>
        getActionHistories({ ...params, order_by: "-action_date" });
    },
    fetchClosedProperties() {
      return getClosedProperties;
    },
    canEditClosedProperty(item) {
      const tableType = item.property_table_type;
      const location = item.property?.location_of_original_data;
      return isValidItem(tableType, location);
    },
    canEditActionHistory(item) {
      const actionType = item.action_type;
      const tableType = item.property_table_type;
      const location = item.property?.location_of_original_data;
      return actionType === 0 && isValidItem(tableType, location);
    },
    deleteActionHistory(item) {
      if (!this.canEditActionHistory(item)) return;
      this.$emit("emit-delete", {
        message: "指定した来場・送客履歴を削除します。",
        id: item.history_id,
        apiDelete: "deleteCustomerActionHistory",
      });
    },
    deleteClosedProperty(item) {
      if (!this.canEditClosedProperty(item)) return;
      this.$emit("emit-delete", {
        message: "指定した成約履歴を削除します。",
        id: item.closed_property_id,
        apiDelete: "deleteClosedProperty",
      });
    },
    navigateToEditCustomerAction(item) {
      if (!this.canEditActionHistory(item)) return;
      this.paramTLI07005.params.history_id = item.history_id;
      this.$router.replace(this.paramTLI07005);
    },
    navigateToEditClosedProperty(item) {
      if (!this.canEditClosedProperty(item)) return;
      this.paramTLI07007.params.closed_property_id = item.closed_property_id;
      this.$router.replace(this.paramTLI07007);
    },
  },
};
</script>
