<template>
  <FieldSelect2
    :key="`select_prop_${propertyId}_${properties.length}`"
    v-model.number="propertyId"
    class="custom-select2 asset-name-type asset-name-type-1 w-100"
    :long-options="properties" />
</template>

<script>
import { getProperties } from "/src/service/properties.service.js";
import { ORIGINAL_LOCATION } from "/src/frontend/src/constants.js";
import FieldSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";

export default {
  components: {
    FieldSelect2,
  },
  props: {
    modelValue: { type: Number, default: null },
    fetchDelay: { type: Number, default: 0 },
    params: {
      type: Object,
      default: () => ({
        location_of_original_data: ORIGINAL_LOCATION.OTHER,
        company_classification: "他社",
      }),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      properties: [],
    };
  },
  computed: {
    propertyId: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  created() {
    setTimeout(() => {
      this.fetchProperties();
    }, this.fetchDelay);
  },
  methods: {
    async fetchProperties() {
      const params = {
        all: true,
        ...this.params,
      };
      const response = await getProperties(params);
      response.data.items.forEach((el) => {
        this.properties.push({ value: el.property_id, label: el.name });
      });
    },
  },
};
</script>
