<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-3 col-xl-2">
          <div class="form-group">
            <label class="my-0 text-nowrap">期間（登録日）</label>
          </div>
        </div>
        <div class="col-md-9 col-xl-10">
          <div class="row align-items-center">
            <div class="col-sm-5 col-xl-3">
              <InputTime
                v-model:modelvalue="filter.from_date"
                placeholder="下 限" />
            </div>
            <div class="form-group mx-2 mx-sm-0">～</div>
            <div class="col-sm-5 col-xl-3">
              <InputTime
                v-model:modelvalue="filter.to_date"
                placeholder="上 限" />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <div class="col-md-9 col-xl-10">
              <div
                v-if="isValidDateRange"
                class="text-red2 mb-2 fs-12 form-error">
                日付の大小関係が不正です。
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xl-2">
          <div class="form-group">
            <label class="my-0 text-nowrap">問合せ区分</label>
          </div>
        </div>
        <div class="col-md-9 col-xl-10">
          <div class="row align-items-center">
            <div class="col-sm-5 col-xl-3">
              <FieldSelect2
                v-model="filter.inquiry_classification"
                class="custom-select2 w-100"
                :options="inquiryClassification"
                :keyid="'value'"
                :keytext="'label'" />
            </div>
          </div>
        </div>
        <div class="col-12 my-3">
          <div class="text-center">
            <button
              class="btn btn-cus btn-blue-light px-3 m-2 w-105"
              @click="navigateToSearch">
              絞り込み
            </button>
            <button
              :disabled="isFormValid"
              class="btn btn-cus btn-grey px-3 m-2"
              @click="clearFilter">
              検索条件クリア
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inquiryClassification } from "/src/components/inquiry/const.js";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2";
import InputTime from "/src/components/utilitys/inputs/InputTime.vue";
import { getParamsUrl } from "/src/service/utility.service.js";
export default {
  components: {
    InputTime,
    FieldSelect2,
  },
  data() {
    let params = getParamsUrl();
    return {
      filter: {
        inquiry_classification: params.inquiry_classification || "",
        from_date: params.from_date ? params.from_date.replace("-", "/") : "",
        to_date: params.to_date ? params.to_date.replace("-", "/") : "",
      },
      inquiryClassification,
    };
  },
  computed: {
    isFormValid() {
      return !(
        this.filter.from_date !== "" ||
        this.filter.to_date !== "" ||
        this.filter.inquiry_classification !== ""
      );
    },
    isValidDateRange() {
      if (this.filter.from_date == "" || this.filter.to_date == "") {
        return false;
      }
      const startDate = new Date(this.filter.from_date);
      const endDate = new Date(this.filter.to_date);
      if (
        isNaN(startDate.getTime()) ||
        isNaN(endDate.getTime()) ||
        startDate > endDate
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.addEventListener("keydown", this.enterToSearch);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterToSearch);
  },
  methods: {
    clearFilter() {
      this.filter.inquiry_classification = "";
      this.filter.from_date = "";
      this.filter.to_date = "";
      this.showError = false;
    },
    createQueryStringFromData() {
      if (this.filter.from_date != "") {
        this.filter.from_date = this.func
          .formatDate(this.filter.from_date)
          .replaceAll("/", "-");
      }
      if (this.filter.to_date != "") {
        this.filter.to_date = this.func
          .formatDate(this.filter.to_date)
          .replaceAll("/", "-");
      }
      return Object.keys(this.$data.filter)
        .filter((key) => this.$data.filter[key])
        .map((key) => `${key}=${this.$data.filter[key]}`)
        .join("&");
    },
    navigateToSearch() {
      let query = this.createQueryStringFromData() + "&page=1";
      let oldQuery = decodeURI(location.search);
      if (
        oldQuery.slice(1) == query ||
        (query == "&page=1" && oldQuery.slice(1) == "page=1")
      ) {
        this.$emit("reload");
      } else {
        if (query == "&page=1") {
          query = "page=1";
        }
        this.$router.push(`${location.pathname}?${query}`);
      }
    },
    enterToSearch(e) {
      if (e.key == "Enter") {
        this.navigateToSearch();
      }
    },
  },
};
</script>
