<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <BaseLayout>
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="card-title-cus">集 計</h3>
      </div>
    </div>
    <div class="cus-filter">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3 col-xl-2">
            <div class="form-group">
              <label class="my-2 text-nowrap">期 間</label>
              <span class="badge badge-red">必 須</span>
            </div>
          </div>
          <div class="col-md-9 col-xl-10">
            <div class="row">
              <div class="col-sm-5 col-xl-3">
                <InputTime
                  v-model:modelvalue="filter.fromDate"
                  placeholder="下 限" />
                <p v-if="dateError" class="error mt-02">
                  日付の大小関係が不正です。
                </p>
              </div>
              <div class="form-group mx-2 mx-sm-0">～</div>
              <div class="col-sm-5 col-xl-3">
                <InputTime
                  v-model:modelvalue="filter.toDate"
                  placeholder="上 限" />
              </div>
              <div class="col-md-10 col-xl-6 my-3">
                <div class="text-center">
                  <button
                    class="btn btn-cus btn-blue-light px-3 m-2 w-105"
                    @click="submitForm()">
                    <span>集 計</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import InputTime from "/src/components/utilitys/inputs/InputTime.vue";
import { mixinModal } from "/src/mixins";

export default {
  components: {
    BaseLayout,
    InputTime,
  },
  mixins: [mixinModal],
  data() {
    return {
      filter: {
        fromDate: "",
        toDate: "",
      },
      dateError: false,
      msgConfirm: "集計処理を開始します。",
    };
  },
  computed: {
    ...mapState("aggregation", ["statusExport", "actionExport"]),
  },
  watch: {
    "filter.fromDate": function () {
      this.checkTime();
    },
    "filter.toDate": function () {
      this.checkTime();
    },
  },
  methods: {
    ...mapActions("aggregation", ["setAction"]),
    checkTime() {
      if (this.filter.fromDate && this.filter.toDate) {
        let fromTime = new Date(this.filter.fromDate).getTime();
        let toTime = new Date(this.filter.toDate).getTime();
        this.dateError = fromTime > toTime;
      }
    },
    submitForm() {
      if (this.statusExport) {
        this.checkAggregation();
      } else {
        this.checkTime();
        if (!this.filter.fromDate || !this.filter.toDate) {
          this.dateError = true;
        }
        if (!this.dateError) {
          localStorage.setItem(
            "aggregation_from",
            this.filter.fromDate.replaceAll("/", "-")
          );
          localStorage.setItem(
            "aggregation_to",
            this.filter.toDate.replaceAll("/", "-")
          );
          this.checkAggregation();
        }
      }
    },
    checkAggregation() {
      this.setAction(!this.actionExport);
    },
  },
};
</script>

<style scoped>
.mt-02 {
  margin-top: -10px;
}
</style>
