<template>
  <BaseLayout>
    <div class="minh-520">
      <div class="wrap-ct border-0">
        <div class="custom-tab">
          <nav>
            <div class="tab-back">
              <button
                class="btn btn-cus btn-blue-light px-4"
                @click="navigateBack()">
                <span>戻 る</span>
              </button>
            </div>
            <div id="nav-tab" class="nav nav-tabs" role="tablist">
              <a
                id="nav-1-tab"
                class="nav-item nav-link active"
                data-toggle="tab"
                href="#nav-1"
                role="tab"
                aria-controls="nav-1"
                aria-selected="true"
                >メール内容</a
              >
              <a
                id="nav-2-tab"
                class="nav-item nav-link"
                data-toggle="tab"
                href="#nav-2"
                role="tab"
                aria-controls="nav-2"
                aria-selected="false"
                @click="getNewMailInterface()"
                >送信顧客一覧</a
              >
            </div>
          </nav>
          <div id="nav-tabContent" class="tab-content">
            <div
              id="nav-1"
              class="tab-pane fade show active"
              role="tabpanel"
              aria-labelledby="nav-3-tab">
              <div class="mx-4">
                <div class="cus-filter my-0 border-0">
                  <div class="card-body px-0 custom-form">
                    <div class="row align-items-center">
                      <div class="col-12 col-lg-3 col-xl-2 my-2">
                        <label class="mb-0">物 件</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2">
                        <div class="custom-select2">
                          <select
                            class="form-control distribution-select1"
                            disabled>
                            <!-- Property name -->
                            <option value="1">
                              {{ newMailContent.property?.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-xl-1 my-2 text-xl-center">
                        <label class="text-nowrap mb-0">表示区画</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2 mr-sm-3">
                        <div class="custom-select2">
                          <select
                            class="form-control distribution-select1"
                            disabled>
                            <!-- Block name -->
                            <option value="1">
                              {{
                                newMailContent.property_block?.block_name
                              }}&nbsp;({{
                                newMailContent.property_block.layout_type_txt
                              }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3 col-xl-1 my-2">
                        <label class="text-nowrap mb-0">送信日</label>
                      </div>
                      <div class="col-12 col-lg-8 col-xl-2 my-2">
                        <!-- Send date -->
                        <p class="text-nowrap mb-0">
                          {{
                            func.formatDate(newMailContent.plan_send_date, {
                              dateStyle: "long",
                            })
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-lg-3 col-xl-2 my-2">
                        <label>フリーテキスト</label>
                      </div>
                      <div class="col-lg-8 my-2">
                        <!-- free words -->
                        <textarea
                          id=""
                          v-model="newMailContent.free_words"
                          type="text"
                          name=""
                          class="form-control"
                          rows="6"
                          placeholder=""
                          disabled>
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="nav-2"
              class="tab-pane fade"
              role="tabpanel"
              aria-labelledby="nav-1-tab">
              <div class="mx-4 my-3">
                <div class="wapper-table">
                  <div class="row align-items-center mx-2 py-4 mb-1">
                    <div class="col-12 text-right">
                      <label class="fs-18 mb-0">{{ total }}件</label>
                      <label class="my-0 winw-100 mr-2 text-nowrap">
                        メール開封率</label
                      >
                      <input
                        :value="((opened_count / total) * 100).toFixed(1)"
                        type="text"
                        class="form-control w-84 d-inline-block"
                        disabled />
                      <label class="my-0 ml-1 text-nowrap">%</label>
                    </div>
                  </div>
                  <div class="border">
                    <ScrollTableLayout>
                      <table class="table table-striped mb-0">
                        <thead>
                          <tr class="text-nowrap text-center">
                            <th class="w-215 minw-190">
                              <div class="sorted">
                                <span>氏 名</span>
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.full_name_kanji == '+',
                                    }"
                                    @click="
                                      sortField(
                                        'full_name_kanji',
                                        false,
                                        'customer'
                                      )
                                    "
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.full_name_kanji == '-',
                                    }"
                                    @click="
                                      sortField(
                                        'full_name_kanji',
                                        true,
                                        'customer'
                                      )
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th class="w-215 minw-190">
                              <div class="sorted">
                                フリガナ
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.full_name_furigana == '+',
                                    }"
                                    @click="
                                      sortField(
                                        'full_name_furigana',
                                        false,
                                        'customer'
                                      )
                                    "
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.full_name_furigana == '-',
                                    }"
                                    @click="
                                      sortField(
                                        'full_name_furigana',
                                        true,
                                        'customer'
                                      )
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                メールアドレス
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.email == '+',
                                    }"
                                    @click="sortField('email', false, 'customer')"
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.email == '-',
                                    }"
                                    @click="sortField('email', true, 'customer')"
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                <span>住 所</span>
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{ active: sortable.address == '+' }"
                                    @click="sortAddress(false, 'customer')"
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{ active: sortable.address == '-' }"
                                    @click="sortAddress(true, 'customer')"
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                希望問取リ
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.floorPlans == '+',
                                    }"
                                    @click="sortFloorPlans(false, 'customer')"
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.floorPlans == '-',
                                    }"
                                    @click="sortFloorPlans(true, 'customer')"
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                <span>予 算</span>
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.budget_limit == '+',
                                    }"
                                    @click="
                                      sortField('budget_limit', false, 'customer')
                                    "
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.budget_limit == '-',
                                    }"
                                    @click="
                                      sortField('budget_limit', true, 'customer')
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                家族数
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.family_size == '+',
                                    }"
                                    @click="
                                      sortField('family_size', false, 'customer')
                                    "
                                    ><i class="fas fa-caret-up"></i
                                  ></span>
                                  <span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.family_size == '-',
                                    }"
                                    @click="
                                      sortField('family_size', true, 'customer')
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                開封日
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.opened_at == '+',
                                    }"
                                    @click="sortOpenedAt(false)"
                                    ><i class="fas fa-caret-up"></i></span
                                  ><span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.opened_at == '-',
                                    }"
                                    @click="sortOpenedAt(true)"
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                PV回数
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active:
                                        sortable.number_of_page_views == '+',
                                    }"
                                    @click="
                                      sortField(
                                        'number_of_page_views',
                                        false,
                                        'new_mail_property'
                                      )
                                    "
                                    ><i class="fas fa-caret-up"></i></span
                                  ><span
                                    class="sorting_desc"
                                    :class="{
                                      active:
                                        sortable.number_of_page_views == '-',
                                    }"
                                    @click="
                                      sortField(
                                        'number_of_page_views',
                                        true,
                                        'new_mail_property'
                                      )
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div class="sorted">
                                <span>担 当</span>
                                <div class="sorting">
                                  <span
                                    class="sorting_asc"
                                    :class="{
                                      active: sortable.assigned_person == '+',
                                    }"
                                    @click="
                                      sortPerson(
                                        -1,
                                        'new_mail_follow_priority_customers',
                                        'assigned_person'
                                      )
                                    "
                                    ><i class="fas fa-caret-up"></i></span
                                  ><span
                                    class="sorting_desc"
                                    :class="{
                                      active: sortable.assigned_person == '-',
                                    }"
                                    @click="
                                      sortPerson(
                                        1,
                                        'new_mail_follow_priority_customers',
                                        'assigned_person'
                                      )
                                    "
                                    ><i class="fas fa-caret-down"></i
                                  ></span>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(mail, index) in displayItems" :key="index">
                            <td>
                              {{ mail.customer?.full_name_kanji }}
                            </td>
                            <td>
                              {{ mail.customer?.full_name_furigana }}
                            </td>
                            <td class="text-nowrap">
                              <a class="txt-underline">{{
                                mail.customer?.email
                              }}</a>
                            </td>
                            <td>
                              {{ mail.customer?.address_prefecture
                              }}{{ mail.customer?.address_district }}
                            </td>
                            <td class="text-right">
                              {{
                                func.convertEnum(
                                  mail.customer?.demand_floor_plans1,
                                  BLOCK_LAYOUT_TYPE
                                )
                              }}{{
                                mail.customer
                                  ? mail.customer.demand_floor_plans1 ||
                                    mail.customer.demand_floor_plans2
                                    ? "～"
                                    : ""
                                  : ""
                              }}<br />{{
                                func.convertEnum(
                                  mail.customer?.demand_floor_plans2,
                                  BLOCK_LAYOUT_TYPE
                                )
                              }}
                            </td>
                            <td class="text-right">
                              {{ func.formatNumber(mail.customer?.budget_limit) }}
                            </td>
                            <td class="text-right">
                              {{ func.formatNumber(mail.customer?.family_size) }}
                            </td>
                            <td v-if="mail.opened_at" class="text-nowrap text-right">
                              {{
                                func.formatDate(mail.opened_at, {
                                  dateStyle: "long",
                                })
                              }}<br />{{
                                func.formatDate(mail.opened_at, {
                                  timeStyle: "short",
                                })
                              }}
                            </td>
                            <td v-else></td>
                            <td class="text-right">
                              {{
                                func.formatNumber(
                                  mail.new_mail_property?.number_of_page_views
                                )
                              }}
                            </td>
                            <td>
                              {{
                                func.displayValue(
                                  mail.new_mail_follow_priority_customers[0]
                                    ?.assigned_person?.name
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ScrollTableLayout>
                  </div>
                  <BasicPaginate
                    :page="page"
                    :size="size"
                    :pages="pages"
                    :total="total"
                    :click-handler="changePage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "/src/components/layouts/BaseLayout.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";
import { BLOCK_LAYOUT_TYPE } from "/src/constants";
import {
  getNewMails,
  getNewMailContent,
} from "/src/service/new-mail.service.js";
import { getParamsUrl } from "/src/service/utility.service";
import { sortByMultipleFields } from "/src/helpers/sort-array.js";
import { splitIntoPages } from "/src/components/utilitys/utility.js";

export default {
  name: "SendMailConfirm",
  components: {
    BaseLayout,
    ScrollTableLayout,
    BasicPaginate,
  },
  data() {
    return {
      total: 0,
      page: 1,
      pages: 1,
      size: 50,
      params: {
        property_id: this.$route.params.property_id,
        plan_send_date: this.$route.params.plan_send_date,
      },
      newMailContent: {
        new_mail_content_ld: "",
        plan_send_date: "",
        free_words: "",
        property: {
          property_id: "",
          name: "",
        },
        property_block: {
          property_block_id: "",
          name: "",
          layout_type_txt: "",
        },
      },
      newMailList: [],
      opened_count: 0,
      BLOCK_LAYOUT_TYPE,
      arrowUp: "",
      arrowDown: "",
      sortable: {
        budget_limit: null, // - is desc, + is asc
        floorPlans: null,
        address: null,
      },
    };
  },
  computed: {
    displayItems() {
      return splitIntoPages(this.newMailList, this.size)[this.page - 1];
    },
  },
  created() {
    // get params from router
    getNewMailContent(this.params).then((res) => {
      this.newMailContent = res.data;
    });
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    getNewMailInterface() {
      if (this.newMailList.length > 0) {
        return this.newMailList;
      } else {
        let params = getParamsUrl();
        getNewMails({
          ...params,
          all: true,
          is_sent: true,
          property_id: this.params["property_id"],
          plan_send_from: this.params["plan_send_date"],
          plan_send_to: this.params["plan_send_date"],
        }).then((res) => {
          this.newMailList = res.data.items;
          this.total = this.newMailList.length;
          this.pages = Math.ceil(this.total / this.size);
          this.changePage(1); // Go to page 1 as initial
          this.opened_count = res.data.opened_count;
          this.sortData("+customer_id", "customer");
        });
      }
    },
    navigateBack() {
      this.func.navigateBack(this.$router, "new-mails-main");
    },
    sortData(order_by, parent) {
      this.newMailList = sortByMultipleFields(
        this.newMailList,
        order_by,
        parent
      );
      this.changePage(1);
    },
    sortField(field, desc, parent) {
      const sign = desc ? "-" : "+";
      this.sortable[field] = sign;
      this.sortData(`${sign}${field}`, parent);
      this.clearSort(field);
    },
    sortFloorPlans(desc, parent) {
      const sign = desc ? "-" : "+";
      this.sortable.floorPlans = sign;
      this.sortData(
        `${sign}demand_floor_plans1,${sign}demand_floor_plans2`,
        parent
      );
      this.clearSort("floorPlans");
    },
    sortAddress(desc, parent) {
      const sign = desc ? "-" : "+";
      this.sortable.address = sign;
      this.sortData(
        `${sign}address_prefecture,${sign}address_district`,
        parent
      );
      this.clearSort("address");
    },
    sortOpenedAt(desc) {
      const sign = desc ? "-" : "+";
      this.sortable.opened_at = sign;
      this._sortOpenedAt(desc);
      this.clearSort("opened_at");
    },
    _sortOpenedAt(desc) {
      this.newMailList = this.newMailList.sort((a, b) => {
        let a_value = a.opened_at ? new Date(a.opened_at) : new Date(0);
        let b_value = b.opened_at ? new Date(b.opened_at) : new Date(0);
        return (b_value - a_value) * (desc ? 1 : -1);
      });
    },
    sortPerson(numb, name, name2) {
      let self = this.newMailList;
      const sign = numb == 1 ? "-" : "+";
      this.sortable.assigned_person = sign;
      this.newMailList = self.sort((a, b) => {
        let a_value = this._getUserName(a);
        let b_value = this._getUserName(b);
        let compareValues = b_value.localeCompare(a_value);
        return numb * compareValues;
      });
      this.clearSort("assigned_person");
    },
    _getUserName(object) {
      if (object.new_mail_follow_priority_customers.length == 0) return "";
      return object.new_mail_follow_priority_customers[0].assigned_person.name.toLowerCase();
    },
    clearSort(excludeField) {
      for (const key in this.sortable) {
        if (key !== excludeField) this.sortable[key] = null;
      }
    },
  },
};
</script>
