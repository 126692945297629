<template>
  <table class="table table-striped table-bordered">
    <tbody>
      <tr>
        <td>反響先物件名</td>
        <td>{{ data.property_name }}</td>
      </tr>
      <tr>
        <td>氏 名</td>
        <td>{{ data.customer ? data.customer.full_name_kanji : "" }}</td>
      </tr>
      <tr>
        <td>フリガナ</td>
        <td>{{ data.customer ? data.customer.full_name_furigana : "" }}</td>
      </tr>
      <tr>
        <td>性 別</td>
        <td>{{ data.customer ? data.customer.gender : "" }}</td>
      </tr>
      <tr>
        <td>生年月日</td>
        <td>
          {{
            data.customer
              ? func.formatDate(data.customer.birthday, { dateStyle: "long" })
              : ""
          }}
        </td>
      </tr>
      <tr>
        <td>現住所</td>
        <td>{{ address }}</td>
      </tr>
      <tr>
        <td>メールアドレス</td>
        <td>{{ data.customer ? data.customer.email : "" }}</td>
      </tr>
      <tr>
        <td>電話番号（希望連絡先）</td>
        <td>{{ data.customer ? data.customer.telephone_number : "" }}</td>
      </tr>
      <tr>
        <td>希望連絡時間帯</td>
        <td>
          {{ data.preferred_contact_time ? data.preferred_contact_time : "" }}
        </td>
      </tr>
      <tr>
        <td>来場 第一希望日</td>
        <td>
          {{ func.formatDate(data.preferred_date_1, { dateStyle: "long" }) }}
        </td>
      </tr>
      <tr>
        <td>来場 第二希望日</td>
        <td>
          {{ func.formatDate(data.preferred_date_2, { dateStyle: "long" }) }}
        </td>
      </tr>
      <tr>
        <td>来場 第三希望日</td>
        <td>
          {{ func.formatDate(data.preferred_date_3, { dateStyle: "long" }) }}
        </td>
      </tr>

      <tr>
        <td>要 望</td>
        <td>{{ data.inquiry_note ? data.inquiry_note : "" }}</td>
      </tr>
      <tr>
        <td>来場予定人数</td>
        <td>{{ data.number_of_visitors ? data.number_of_visitors : "" }}</td>
      </tr>
      <tr>
        <td>フォロー対応ユーザー名</td>
        <td>{{ data.following_username ? data.following_username : "" }}</td>
      </tr>
      <tr>
        <td>登録日時</td>
        <td>
          {{
            data.created_at
              ? func.formatDate(data.created_at, {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              : ""
          }}
        </td>
      </tr>
      <tr>
        <td>更新日時</td>
        <td>
          {{
            data.updated_at
              ? func.formatDate(data.updated_at, {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              : ""
          }}
        </td>
      </tr>
      <tr>
        <td>更新者</td>
        <td>{{ data.updated_by_user ? data.updated_by_user.name : "" }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["data"],
  computed: {
    address() {
      const customer = this.data.customer;
      if (!customer) return "";
      const postcode = customer.address_postcode || "";
      const prefecture = customer.address_prefecture || "";
      const district = customer.address_district || "";
      const detail = customer.address_detail || "";
      const building = customer.address_building_name || "";
      return [`${postcode}${prefecture}${district}${detail}`, building]
        .filter((val) => val)
        .join(" ");
    },
  },
};
</script>
