<template>
  <div
    id="nav-2"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-1-tab">
    <div class="m-4">
      <div class="wapper-table">
        <div class="row align-items-center mx-2 py-4 mb-1">
          <div class="col-12 text-right">
            <label class="fs-18 mb-0">{{ customers.length }}件</label>
          </div>
        </div>
        <div class="border">
          <ScrollTableLayout>
            <table class="table table-striped mb-0">
              <thead>
                <tr class="text-nowrap text-center">
                  <th class="w-215 minw-190">
                    <div class="sorted">
                      <span>氏 名</span>
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: sortable.full_name_kanji == '+' }"
                          @click="sortField('full_name_kanji', false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{ active: sortable.full_name_kanji == '-' }"
                          @click="sortField('full_name_kanji', true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th class="w-215 minw-190">
                    <div class="sorted">
                      フリガナ
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{
                            active: sortable.full_name_furigana == '+',
                          }"
                          @click="sortField('full_name_furigana', false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{
                            active: sortable.full_name_furigana == '-',
                          }"
                          @click="sortField('full_name_furigana', true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      メールアドレス
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{
                            active: sortable.email == '+',
                          }"
                          @click="sortField('email', false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{
                            active: sortable.email == '-',
                          }"
                          @click="sortField('email', true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      <span>住 所</span>
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: sortable.address == '+' }"
                          @click="sortAddress(false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{ active: sortable.address == '-' }"
                          @click="sortAddress(true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      希望問取リ
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: sortable.floorPlans == '+' }"
                          @click="sortFloorPlans(false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{ active: sortable.floorPlans == '-' }"
                          @click="sortFloorPlans(true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      <span>予 算</span>
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: sortable.budget_limit == '+' }"
                          @click="sortField('budget_limit', false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{ active: sortable.budget_limit == '-' }"
                          @click="sortField('budget_limit', true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      家族数
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{
                            active: sortable.family_size == '+',
                          }"
                          @click="sortField('family_size', false)"
                          ><i class="fas fa-caret-up"></i
                        ></span>
                        <span
                          class="sorting_desc"
                          :class="{
                            active: sortable.family_size == '-',
                          }"
                          @click="sortField('family_size', true)"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in displayItems" :key="index">
                  <td>{{ row.full_name_kanji }}</td>
                  <td>{{ row.full_name_furigana }}</td>
                  <td class="text-nowrap">
                    <span class="txt-underline" style="color: #007bff">
                      {{ row.email }}
                    </span>
                  </td>
                  <td class="text-nowrap">{{ getAddress(row) }}</td>
                  <td class="text-right">
                    <span>{{ row.demand_floor_plans1_txt }}～</span>
                    <br
                      v-show="
                        row.demand_floor_plans1_txt &&
                        row.demand_floor_plans2_txt
                      " />
                    <span>{{ row.demand_floor_plans2_txt }}</span>
                  </td>
                  <td class="text-right">
                    {{ func.formatNumber(row.budget_limit) }}
                  </td>
                  <td class="text-right">{{ row.family_size }}</td>
                </tr>
              </tbody>
            </table>
          </ScrollTableLayout>
        </div>
        <BasicPaginate
          :page="page"
          :size="size"
          :pages="pages"
          :total="customers.length"
          :click-handler="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";

import { splitIntoPages } from "/src/components/utilitys/utility.js";

export default {
  components: {
    ScrollTableLayout,
    BasicPaginate,
  },
  props: {
    customers: { type: Array, default: () => [] },
  },
  emits: ["sort-field"],
  data() {
    return {
      page: 1,
      size: 50,
      sortable: {
        // - is desc, + is asc
        full_name_kanji: null,
        full_name_furigana: null,
        email: null,
        address: null,
        floorPlans: null,
        budget_limit: null,
        family_size: null,
      },
    };
  },
  computed: {
    displayItems() {
      return splitIntoPages(this.customers, this.size)[this.page - 1];
    },
    pages() {
      return Math.ceil(this.customers.length / this.size);
    },
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    getAddress(item) {
      const prefecture = item.address_prefecture || "";
      const district = item.address_district || "";
      return `${prefecture}${district}`;
    },
    sortField(field, desc) {
      const sign = desc ? "-" : "+";
      this.sortable[field] = sign;
      this.emitSort(`${sign}${field}`);
      this.clearSort(field);
    },
    sortFloorPlans(desc) {
      const sign = desc ? "-" : "+";
      this.sortable.floorPlans = sign;
      this.emitSort(`${sign}demand_floor_plans1,${sign}demand_floor_plans2`);
      this.clearSort("floorPlans");
    },
    sortAddress(desc) {
      const sign = desc ? "-" : "+";
      this.sortable.address = sign;
      this.emitSort(`${sign}address_prefecture,${sign}address_district`);
      this.clearSort("address");
    },
    emitSort(fieldName) {
      this.$emit("sort-field", fieldName);
      this.changePage(1); // Go to page 1 as initial
    },
    clearSort(excludeField) {
      for (const key in this.sortable) {
        if (key !== excludeField) this.sortable[key] = null;
      }
    },
  },
};
</script>
