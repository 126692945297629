<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-xl-3 my-2">
      フォロー日
      <span v-show="!disabled" class="badge badge-red">必 須</span>
    </div>
    <div class="col-12 col-md-5 col-xl-3 my-2">
      <InputTime
        v-model:modelvalue="selectDate"
        class="mb-0"
        :class="{ 'field-error': error }"
        :disabled="disabled" />
    </div>
  </div>
  <div v-show="error !== ''" class="row align-items-center mb-2">
    <div class="col-12 col-md-4 col-xl-3"></div>
    <div class="col">
      <p class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import InputTime from "/src/components/utilitys/inputs/InputTime.vue";

export default {
  components: {
    InputTime,
  },
  props: {
    modelValue: { type: String, default: null },
    error: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {};
  },
  computed: {
    selectDate: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
