<template>
  <div class="row mb-4">
    <div class="col-md-7">
      <h3 class="card-title-cus mb-3">
        {{
          selfCompany
            ? "販売受託・ルジェンテ新築物件"
            : "他社媒介・紹介新築等物件"
        }}
      </h3>
    </div>
    <div class="col-md-5">
      <div class="text-right">
        <button
          class="btn btn-cus btn-blue-light btn-ok px-3 mx-2 my-sm-0 minw-100"
          @click="$router.go(-1)">
          <span>戻 る</span>
        </button>
      </div>
    </div>
  </div>
  <div class="cus-filter">
    <div class="card-body">
      <div class="row">
        <div class="col-md-1">
          <label
            style="bottom: 0.5rem"
            class="position-relative text-nowrap my-2 mr-4"
            >物件名</label
          >
        </div>
        <div class="col-sm-10 col-md-9 col-xl-6">
          <div class="input-group">
            {{ propertyDetail.name }}
          </div>
          <div class="row mt-4">
            <div class="col-sm-5 mt-3">
              <div class="contruc-img">
                <!-- property.appearance_image_link -->
                <img
                  :src="
                    propertyDetail.appearance_image_link
                      ? IMAGE_PROPERTIES + propertyDetail.appearance_image_link
                      : ''
                  "
                  alt="img"
                  class="img-fluid" />
              </div>
              <div class="text-center fs-16 my-2">外観画像</div>
            </div>
            <div class="col-sm-5 mt-3">
              <!-- properties.guide_map_image_link -->
              <div class="contruc-img">
                <img
                  :src="
                    propertyDetail.guide_map_image_link
                      ? IMAGE_PROPERTIES + propertyDetail.guide_map_image_link
                      : ''
                  "
                  alt="img"
                  class="img-fluid" />
              </div>
              <div class="text-center fs-16 my-2">現地案内図</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-tab">
    <nav>
      <div id="nav-tab" class="nav nav-tabs" role="tablist">
        <a
          id="nav-1-tab"
          ref="#nav-1"
          class="nav-item nav-link active"
          data-toggle="tab"
          href="#nav-1"
          role="tab"
          aria-controls="nav-1"
          aria-selected="true"
          >区画一覧</a
        >
        <a
          id="nav-2-tab"
          ref="#nav-2"
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-2"
          role="tab"
          aria-controls="nav-2"
          aria-selected="false"
          >棟情報</a
        >
        <a
          v-if="user_object.role != USER_ROLE.VIEWER"
          id="nav-3-tab"
          ref="#nav-3"
          class="nav-item nav-link"
          data-toggle="tab"
          href="#nav-3"
          role="tab"
          aria-controls="nav-3"
          aria-selected="false"
          @click="handleClickMailTab()"
          >レコメンド顧客一覧</a
        >
      </div>
    </nav>
    <div id="nav-tabContent" class="tab-content">
      <DynamicPropertyBlockList
        :trigger="blockTrigger"
        :property="propertyDetail"
        :self-company="selfCompany" />
      <DynamicPropertyDetail
        :property="propertyDetail"
        @navigateToSearchProperties="navigateToSearchProperties" />
      <DynamicPropertyRecommendCustomers
        v-if="user_object.role != USER_ROLE.VIEWER"
        :trigger="mailTrigger"
        :property="propertyDetail" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import DynamicPropertyBlockList from "/src/components/properties/DynamicPropertyBlockList.vue";
import DynamicPropertyDetail from "/src/components/properties/DynamicPropertyDetail.vue";
import DynamicPropertyRecommendCustomers from "/src/components/properties/DynamicPropertyRecommendCustomers.vue";
import { getPropertyDetail } from "/src/service/properties.service";
import {
  USER_ROLE,
  ORIGINAL_LOCATION,
  IMAGE_PROPERTIES,
} from "/src/constants.js";
export default {
  components: {
    DynamicPropertyBlockList,
    DynamicPropertyDetail,
    DynamicPropertyRecommendCustomers,
  },
  data() {
    return {
      propertyDetail: {},
      USER_ROLE,
      IMAGE_PROPERTIES,
      blockTrigger: 0,
      mailTrigger: 0,
      isFistClickMail: true,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    selfCompany() {
      return (
        this.propertyDetail.location_of_original_data ==
        ORIGINAL_LOCATION.DYNAMIC
      );
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name !== "property-detail") return;
        const reload = this.$route.query.reload;
        if (reload === "block") this.blockTrigger += 1;
        if (reload === "mail") this.mailTrigger += 1;
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchData();
    let hash = window.location.hash;
    if (hash) {
      this.$refs[hash].click();
    }
  },
  methods: {
    fetchData() {
      getPropertyDetail(this.$route.params.id).then((res) => {
        this.propertyDetail = res.data;
        this.$store.dispatch(
          "properties/setRouteName",
          this.propertyDetail.location_of_original_data
        );
        this.blockTrigger += 1;
      });
    },
    navigateToSearchProperties() {
      if (this.selfCompany) {
        this.func.navigateBack(this.$router, "properties-in-house");
      } else {
        this.func.navigateBack(this.$router, "properties-other");
      }
    },
    handleClickMailTab() {
      if (this.isFistClickMail) {
        this.mailTrigger += 1;
        this.isFistClickMail = false;
      }
    },
  },
};
</script>
