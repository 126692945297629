<template>
  <div
    id="nav-2"
    class="tab-pane fade"
    role="tabpanel"
    aria-labelledby="nav-2-tab">
    <div class="position-relative">
      <div class="tab-note">※お客様への提示項目は色付きで表示しています</div>
    </div>
    <div class="custom-table custom-table-3 py-4">
      <table class="table">
        <tbody>
          <tr>
            <td class="w-350">棟番号</td>
            <td>{{ property.building_number }}</td>
          </tr>
          <tr class="active">
            <td>所在地</td>
            <td>
              {{ property.location_prefecture }}{{ property.location_district
              }}{{ property.location_detail }}
            </td>
          </tr>
          <tr class="active">
            <td>最寄駅①</td>
            <td>
              {{
                interpolationStation(
                  property.nearest_station_railway_1,
                  property.nearest_station_1,
                  property.walking_to_nearest_station_1
                )
              }}
            </td>
          </tr>
          <tr>
            <td>最寄駅①までバス</td>
            <td>
              {{
                func.showValue(property.bus_to_nearest_station_1, {
                  suffix: "分",
                  includeZero: true,
                })
              }}
            </td>
          </tr>
          <tr class="active">
            <td>最寄駅②</td>
            <td>
              {{
                interpolationStation(
                  property.nearest_station_railway_2,
                  property.nearest_station_2,
                  property.walking_to_nearest_station_2
                )
              }}
            </td>
          </tr>
          <tr>
            <td>最寄駅②までバス</td>
            <td>
              {{
                func.showValue(property.bus_to_nearest_station_2, {
                  suffix: "分",
                  includeZero: true,
                })
              }}
            </td>
          </tr>
          <tr class="active">
            <td>最寄駅③</td>
            <td>
              {{
                interpolationStation(
                  property.nearest_station_railway_3,
                  property.nearest_station_3,
                  property.walking_to_nearest_station_3
                )
              }}
            </td>
          </tr>
          <tr>
            <td>最寄駅③までバス</td>
            <td>
              {{
                func.showValue(property.bus_to_nearest_station_3, {
                  suffix: "分",
                  includeZero: true,
                })
              }}
            </td>
          </tr>
          <tr>
            <td>区画最低価格～区画最高価格</td>
            <td>
              {{
                func.showValue(
                  func.formatNumber(
                    func.convertAndRoundToMY(property.block_price_min)
                  ),
                  { suffix: "万円", includeZero: true }
                )
              }}
              {{
                property.block_price_min || property.block_price_max ? "～" : ""
              }}
              {{
                func.showValue(
                  func.formatNumber(
                    func.convertAndRoundToMY(property.block_price_max)
                  ),
                  { suffix: "万円", includeZero: true }
                )
              }}
            </td>
          </tr>
          <tr>
            <td>区画最低間取り～区画最高間取り</td>
            <td>
              {{
                func.convertEnum(
                  property.block_layout_type_min,
                  BLOCK_LAYOUT_TYPE
                )
              }}
              {{
                property.block_layout_type_min || property.block_layout_type_max
                  ? "～"
                  : ""
              }}
              {{
                func.convertEnum(
                  property.block_layout_type_max,
                  BLOCK_LAYOUT_TYPE
                )
              }}
            </td>
          </tr>
          <tr>
            <td>区画最低面積～区画最高面積</td>
            <td>
              {{
                func.showValue(
                  property.block_area_min
                    ? func.formatNumber(property.block_area_min, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : func.formatNumber(property.block_area_min),
                  {
                    suffix: "㎡",
                    includeZero: true,
                  }
                )
              }}
              {{
                property.block_area_min || property.block_area_max ? "～" : ""
              }}
              {{
                func.showValue(
                  property.block_area_max
                    ? func.formatNumber(property.block_area_max, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : func.formatNumber(property.block_area_max),
                  {
                    suffix: "㎡",
                    includeZero: true,
                  }
                )
              }}
            </td>
          </tr>
          <tr class="active">
            <td>構造・規模</td>
            <td>{{ property.structure_and_scale }}</td>
          </tr>
          <tr class="active">
            <td>総戸数</td>
            <td>
              {{
                func.showValue(func.formatNumber(property.number_of_houses), {
                  suffix: "戸",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>階 建</td>
            <td>
              {{ func.showValue(property.number_of_floors, { suffix: "階" }) }}
            </td>
          </tr>
          <tr class="active">
            <td>敷地面積</td>
            <td>
              {{
                func.showValue(
                  property.site_area
                    ? func.formatNumber(property.site_area, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : func.formatNumber(property.site_area),
                  {
                    suffix: "㎡",
                    includeZero: true,
                  }
                )
              }}
            </td>
          </tr>
          <tr class="active">
            <td>用途地域</td>
            <td>{{ property.use_district }}</td>
          </tr>
          <tr class="active">
            <td>建築確認番号</td>
            <td>{{ property.building_confirmation_number }}</td>
          </tr>
          <tr class="active">
            <td>確認番号取得日</td>
            <td>
              {{
                func.formatDate(
                  property.acquisition_date_of_building_confirmation,
                  { dateStyle: "long" }
                )
              }}
            </td>
          </tr>
          <tr class="active">
            <td>竣工日</td>
            <td>
              {{
                func.formatDate(property.completion_date, { dateStyle: "long" })
              }}
            </td>
          </tr>
          <tr class="active">
            <td>権利形態</td>
            <td>{{ property.right_type }}</td>
          </tr>
          <tr class="active">
            <td v-if="tli">売主1</td>
            <td v-else>分譲会社1</td>
            <td>
              {{ property.seller_owner_1_?.sales_company_name }}
            </td>
          </tr>
          <tr class="active">
            <td v-if="tli">売主2</td>
            <td v-else>分譲会社2</td>
            <td>
              {{ property.seller_owner_2_?.sales_company_name }}
            </td>
          </tr>
          <tr class="active">
            <td v-if="tli">売主3</td>
            <td v-else>分譲会社3</td>
            <td>
              {{ property.seller_owner_3_?.sales_company_name }}
            </td>
          </tr>
          <tr class="active">
            <td v-if="tli">売主4</td>
            <td v-else>分譲会社4</td>
            <td>
              {{ property.seller_owner_4_?.sales_company_name }}
            </td>
          </tr>
          <tr class="active">
            <td v-if="tli">売主5</td>
            <td v-else>分譲会社5</td>
            <td>
              {{ property.seller_owner_5_?.sales_company_name }}
            </td>
          </tr>
          <tr class="active">
            <td>施工会社</td>
            <td>{{ property.construction_company }}</td>
          </tr>
          <tr class="active">
            <td>管理会社</td>
            <td>{{ property.management_company }}</td>
          </tr>
          <tr class="active">
            <td>管理形態</td>
            <td>
              {{ func.convertEnum(property.management_type, MANAGEMENT_TYPE) }}
            </td>
          </tr>
          <tr class="active">
            <td>管理員の勤務形態</td>
            <td>
              {{
                func.convertEnum(
                  property.manager_working_style,
                  MANAGER_WORKING_STYLE
                )
              }}
            </td>
          </tr>
          <tr class="active">
            <td>駐車場台数</td>
            <td>
              {{ func.showValue(property.parking_lot_count, { suffix: "台" }) }}
            </td>
          </tr>
          <tr v-if="!tli" class="active">
            <td>駐車場空き有無</td>
            <td>
              {{
                func.convertEnum(
                  property.parking_lot_status,
                  PARKING_LOT_STATUS
                )
              }}
            </td>
          </tr>
          <tr class="active">
            <td>駐車場備考</td>
            <td style="white-space: pre-wrap">
              {{ property.parking_lot_note }}
            </td>
          </tr>
          <tr class="active">
            <td>コンシェルジュサービス</td>
            <td v-if="property.concierge_service == 0">✕</td>
            <td v-else>○</td>
          </tr>
          <tr class="active">
            <td>24時間有人管理</td>
            <td v-if="property.all_day_manned_management == 0">✕</td>
            <td v-else>○</td>
          </tr>
          <tr class="active">
            <td>取引形態</td>
            <td>{{ property.trade_type }}</td>
          </tr>
          <tr class="active">
            <td>備 考</td>
            <td style="white-space: pre-wrap">
              {{ property.property_note }}
            </td>
          </tr>
          <tr>
            <td>レコメンド対象除外フラグ</td>
            <td v-if="property.recommend_stop_flag">✓</td>
            <td v-else></td>
          </tr>
          <tr v-if="tli">
            <td>見学予約ボタン設置フラグ</td>
            <td v-if="property.tour_available_flag">✓</td>
            <td v-else></td>
          </tr>
          <tr>
            <td>登録日時</td>
            <td>
              {{
                func.formatDate(property.created_at, {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              }}
            </td>
          </tr>

          <tr>
            <td>更新日時</td>
            <td>
              {{
                func.formatDate(property.updated_at, {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              }}
            </td>
          </tr>
          <tr>
            <td>更新者</td>
            <td>
              {{
                func.showValue(property.updated_by_user, {
                  getProperty: "name",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mb-4 text-center">
      <button
        v-if="user_object.role != USER_ROLE.VIEWER"
        type="button"
        class="btn btn-cus btn-blue-light m-2 ml-md-0 minw-100"
        @click="
          $router.push({
            name: 'property-update',
            params: { id: property.property_id },
          })
        ">
        <span>編 集</span>
      </button>
      <button
        type="button"
        class="btn btn-cus btn-blue-light btn-ok m-2 minw-100"
        @click="$emit('navigateToSearchProperties')">
        <span>戻 る</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  BLOCK_LAYOUT_TYPE,
  MANAGER_WORKING_STYLE,
  MANAGEMENT_TYPE,
  PARKING_LOT_STATUS,
  ORIGINAL_LOCATION,
  USER_ROLE,
} from "/src/constants";
import { interpolationStation } from "/src/components/utilitys/utility.js";
export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      USER_ROLE,
      BLOCK_LAYOUT_TYPE,
      MANAGER_WORKING_STYLE,
      MANAGEMENT_TYPE,
      PARKING_LOT_STATUS,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    tli() {
      return (
        this.property.location_of_original_data === ORIGINAL_LOCATION.DYNAMIC
      );
    },
  },
  methods: {
    interpolationStation(
      nearest_railway,
      nearest_station,
      walk_to_near_station
    ) {
      return interpolationStation(
        nearest_railway,
        nearest_station,
        walk_to_near_station
      );
    },
  },
};
</script>
