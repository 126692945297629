<template>
  <div class="row align-items-center">
    <div class="col-6">
      <label class="mx-2 mx-md-4 py-4 mb-0">{{ tableLabel }}</label>
    </div>
    <div v-if="navButton" class="col-6">
      <div class="mx-2 mx-md-4 py-4 text-right">
        <FunctionalButton
          class="btn-blue-light"
          :text="navButton.text"
          @click="navigateTo()" />
      </div>
    </div>
  </div>
  <div class="wapper-table">
    <ScrollTableLayout>
      <table
        class="table table-middle table-line table-border-vertical"
        :class="tableClass">
        <slot></slot>
      </table>
    </ScrollTableLayout>
    <BasicPaginate
      :page="page"
      :size="size"
      :pages="pages"
      :total="total"
      :click-handler="fetchData" />
  </div>
</template>

<script>
import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";
import BasicPaginate from "/src/components/commons/BasicPaginate.vue";
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";

export default {
  components: {
    FunctionalButton,
    BasicPaginate,
    ScrollTableLayout,
  },
  props: {
    modelValue: { type: Object, default: null },
    tableLabel: { type: String, default: "" },
    navButton: { type: Object, default: null },
    fetchFunc: { type: Function, default: null },
    fetchDelay: { type: Number, default: 0 },
    tableClass: { type: String, default: "table-striped" },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      items: [],
      size: 50,
      total: 0,
      page: 1,
      pages: 1,
    };
  },
  mounted() {
    setTimeout(() => {
      this.fetchData(this.page);
    }, this.fetchDelay);
  },
  methods: {
    fetchData(page) {
      this.page = page;
      const searchParams = {
        customer_id: this.$route.params.customer_id,
        order_by: "-created_at",
        page: this.page,
        size: this.size,
      };
      this.fetchFunc(searchParams).then((response) => {
        this.total = response.data["total"];
        this.pages = response.data["pages"];
        this.items = response.data["items"];
        this.$emit("update:modelValue", this.$data);
      });
    },
    navigateTo() {
      const param = this.navButton?.routeParam;
      if (!param) return;
      this.$router.replace(param);
    },
  },
};
</script>
