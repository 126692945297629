import Login from "./views/Login";
import LoginRedirect from "./views/LoginRedirect";
import { createRouter, createWebHistory } from "vue-router";
import {
  UserView,
  UserDashboard,
  UserAdd,
  UserEdit,
  UserDetail,
} from "./views/user";
import {
  DynamicProperties,
  PropertyView,
  SearchProperties,
  RegisterProperty,
  UpdateProperties,
} from "./views/properties";
import RecommendNew from "/src/views/recommend/RecommendNew.vue";
import RecommendOld from "/src/views/recommend/RecommendOld.vue";
import RecommendProperties from "./views/recommend/RecommendProperties.vue";
import FollowCustomer from "./views/follow-priority-customer/FollowCustomer.vue";
// import MailFollowCustomer from "./views/new-mails/MailFollowCustomer.vue";
import * as customers from "./views/customers";
import { ContractRegistry, ContractUpdate } from "./views/closed-properties";
import { InquiryReservation } from "./views/inquiry-reservation";
import ConfirmMailSent from "./views/new-mails/ConfirmMailSent.vue";
import InquiryDetailViews from "/src/views/inquiry-reservation/InquiryDetailViews.vue";
import PageNotFound from "./views/PageNotFound.vue";

import FollowUpAssignment from "./views/follow-up-assignment/FollowUpAssignment.vue";
import DynamicBlockDetail from "/src/views/dynamic-block/DynamicBlockDetail.vue";
import OtherBlockRegistryDetail from "/src/views/dynamic-block/OtherBlockRegistryDetail.vue";
import UpdateBlockDetail from "/src/views/dynamic-block/UpdateBlockDetail.vue";

import { USER_ROLE } from "./constants";
import breadcrumbs from "./helpers/breadcrumbs";
import store from "./store";
import * as salesFollowHistories from "/src/views/sales-follow-histories";
import SearchSalesForce from "./views/salesforce-properties/SearchSalesForce.vue";
import FreemailSetting from "/src/views/freemail-setting/FreemailSetting.vue";
import MailReference from "/src/views/new-mails/MailReference.vue";
import NewMail from "/src/views/new-mails/NewMail.vue";
import SendingPlan from "/src/views/new-mails/SendingPlan.vue";
import UpdateMailSent from "/src/views/new-mails/UpdateMailSent.vue";
import AggregationV2 from "/src/views/aggregation/AggregationV2.vue";

store.getters["user/getUserObject"];

let routes = [
  {
    path: "/confirm-user",
    name: "redirect-login",
    component: LoginRedirect,
  },
  {
    path: "/",
    redirect: (to) => {
      return {
        path: "/confirm-user",
        query: { redirect_url: to.query.redirect_url },
      };
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: Login,
  // },
  {
    path: "/users",
    component: UserView,
    children: [
      {
        // TLI03001
        path: "",
        name: "users",
        component: UserDashboard,
        meta: { ...breadcrumbs.users },
      },
    ],
  },
  {
    path: "/user",
    component: UserView,
    children: [
      {
        // TLI03002
        path: "register",
        name: "user-add",
        component: UserAdd,
        meta: { ...breadcrumbs.userAdd },
      },
      {
        // TLI03004
        path: ":user_id/update",
        name: "user-edit",
        component: UserEdit,
        meta: { ...breadcrumbs.userEdit },
      },
      {
        // TLI03003
        path: ":user_id",
        name: "user-detail",
        component: UserDetail,
        meta: { ...breadcrumbs.usersDetail },
      },
    ],
  },
  {
    // TLI04001
    path: "/properties",
    component: PropertyView,
    children: [
      {
        path: "in-house",
        name: "properties-in-house",
        component: SearchProperties,
      },
      {
        path: "other",
        name: "properties-other",
        component: SearchProperties,
      },
    ],
    meta: { ...breadcrumbs.properties },
  },
  {
    path: "/property",
    component: PropertyView,
    children: [
      {
        path: "other",
        children: [
          {
            // TLI04008
            path: ":property_id/register",
            name: "other-block-registry-detail",
            component: OtherBlockRegistryDetail,
            meta: { ...breadcrumbs.otherBlockRegistryDetail },
          },
          {
            // TLI04007
            path: "register",
            name: "properties-other-register",
            component: RegisterProperty,
            meta: { ...breadcrumbs.registryProperties },
          },
        ],
      },
      {
        path: ":id",
        children: [
          {
            // TLI04002
            path: "",
            name: "property-detail",
            component: DynamicProperties,
            meta: { ...breadcrumbs.dynamicProperties },
          },
          {
            // TLI04003
            path: "update",
            name: "property-update",
            component: UpdateProperties,
            meta: { ...breadcrumbs.updateProperties },
          },
        ],
      },
    ],
  },
  {
    path: "/recommend-properties/:uuid",
    name: "recommend-properties",
    component: RecommendProperties,
  },
  {
    // TLI07014
    path: "/priority-customers",
    name: "priority-customers",
    component: FollowCustomer,
    meta: { ...breadcrumbs.followCustomer },
  },
  {
    path: "/inquiry-reservation",
    children: [
      {
        // TLI07011
        path: "",
        name: "inquiry-reservation",
        component: InquiryReservation,
        meta: { ...breadcrumbs.inquiryReservationList },
      },
      {
        // TLI07012
        path: ":inquiry_id",
        name: "inquiry-reservation-id",
        component: InquiryDetailViews,
        meta: { ...breadcrumbs.inquiryReservationDetail },
      },
    ],
  },
  {
    // TLI07013
    path: "/follow-up-assignment",
    name: "follow-up-assignment",
    component: FollowUpAssignment,
    meta: { ...breadcrumbs.followUpAssignment },
  },
  {
    // TLI07001
    path: "/customers",
    name: "customers",
    component: customers.CustomerSearch,
    meta: { ...breadcrumbs.customerSearch },
  },
  {
    path: "/customer/:customer_id",
    children: [
      {
        // TLI07002
        path: "",
        name: "customer-detail",
        component: customers.CustomerDetail,
        meta: { ...breadcrumbs.customerDetail },
      },
      {
        // TLI07003
        path: "actions",
        name: "customer-action",
        component: customers.CustomerAction,
        meta: { ...breadcrumbs.customerAction },
      },
    ],
  },
  {
    path: "/customer-action-histories",
    children: [
      {
        // TLI07004
        path: ":customer_id/add",
        name: "history-registry",
        component: customers.HistoryRegistry,
        meta: { ...breadcrumbs.historyRegistry },
      },
      {
        // TLI07005
        path: ":history_id/update",
        name: "history-update",
        component: customers.HistoryUpdate,
        meta: { ...breadcrumbs.historyUpdate },
      },
    ],
  },
  {
    path: "/closed-properties",
    children: [
      {
        // TLI07006
        path: ":customer_id/add",
        name: "contract-registry",
        component: ContractRegistry,
        meta: { ...breadcrumbs.contractRegistry },
      },
      {
        // TLI07007
        path: ":closed_property_id/update",
        name: "contract-update",
        component: ContractUpdate,
        meta: { ...breadcrumbs.contractUpdate },
      },
    ],
  },
  {
    path: "/sales-follow-histories",
    children: [
      {
        //TLI07008
        path: ":customer_id/add",
        name: "add-sales-follow",
        component: salesFollowHistories.Register,
        meta: { ...breadcrumbs.salesFollowRegistry },
      },
      {
        //TLI07009
        path: ":history_id",
        name: "detail-sales-follow",
        component: salesFollowHistories.Detail,
        meta: { ...breadcrumbs.salesFollowDetail },
      },
      {
        //TLI07010
        path: ":history_id/update",
        name: "update-sales-follow",
        component: salesFollowHistories.Update,
        meta: { ...breadcrumbs.salesFollowUpdate },
      },
    ],
  },
  {
    path: "/error404",
    name: "error404",
    component: PageNotFound,
  },
  { path: "/:pathMatch(.*)*", redirect: { name: "error404" } },
  {
    // TLI04006
    path: "/properties/used",
    name: "properties-used",
    component: SearchSalesForce,
    meta: { ...breadcrumbs.propertiesUsed },
  },
  {
    path: "/property-block",
    children: [
      {
        // TLI04009 salesforce
        path: "reference-sf/:uuid",
        name: "property-reference-sf",
        meta: { title: "【東急リバブル】マンション紹介" },
        component: RecommendOld,
      },
      {
        //TLI04009
        path: "reference/:uuid",
        name: "property-reference",
        meta: { title: "【東急リバブル】マンション紹介" },
        component: RecommendNew,
      },
      {
        //TLI04004
        path: ":block_id",
        name: "property-block",
        component: DynamicBlockDetail,
        meta: { ...breadcrumbs.DynamicSeflBlockDetail },
      },
      {
        // TLI04005
        path: ":block_id/update",
        name: "property-block-update",
        component: UpdateBlockDetail,
        meta: { ...breadcrumbs.updateBlockDetail },
      },
    ],
  },
  {
    // TLI12001
    path: "/freemail-setting",
    name: "freemail-setting",
    component: FreemailSetting,
    meta: { ...breadcrumbs.freemailSetting },
  },
  {
    path: "/new-mails",
    name: "new-mails",
    meta: { ...breadcrumbs.newMail },
    children: [
      {
        // TLI13001
        path: "",
        name: "new-mails-main",
        component: NewMail,
      },
      {
        path: "reference/:uuid",
        name: "mail-reference",
        component: MailReference,
      },
      {
        // TLI13002
        path: "plans",
        name: "sending-plan",
        component: SendingPlan,
        meta: { ...breadcrumbs.sendingPlan },
      },
      {
        // TLI13002 - update
        path: "update/:property_id/:plan_send_date",
        name: "update-mail-sent",
        component: UpdateMailSent,
        meta: { ...breadcrumbs.sendingPlan },
      },
      {
        // TLI13003
        path: "finish-confirm/:property_id/:plan_send_date",
        name: "new-mails-finish-confirm",
        component: ConfirmMailSent,
        meta: { ...breadcrumbs.confirmSendMail },
      },
    ],
  },
  // {
  //   path: "/new_mails",
  //   name: "new-mails",
  //   component: MailFollowCustomer,
  // },
  {
    path: "/aggregate-v2",
    name: "aggregate-v2",
    component: AggregationV2,
    meta: { ...breadcrumbs.aggregationV2 },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add login for localhost
if (location.hostname === "localhost") {
  router.addRoute({
    path: "/login",
    name: "login",
    component: Login,
  });
}

router.beforeEach(async (to, from, next) => {
  let userInfo = store.state.user.user_object;
  document.title = createTitle(to);
  switch (to.name) {
    case "users":
    case "user-detail":
    case "user-add":
    case "user-edit":
      if (userInfo.role === USER_ROLE.ADMIN) {
        next();
      } else next({ name: "error404" });
      break;
    case "inquiry-reservation":
      if ([USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(userInfo.role)) {
        next();
      } else next({ name: "error404" });
      break;
    case "inquiry-reservation-id":
      if ([USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(userInfo.role)) {
        next();
      } else next({ name: "error404" });
      break;
    case "priority-customers":
      if (
        [USER_ROLE.EDITOR].includes(userInfo.role) &&
        userInfo.follow_available_flag === true
      ) {
        next();
      } else next({ name: "error404" });
      break;
    case "follow-up-assignment":
      if (userInfo.role === USER_ROLE.ADMIN) {
        next();
      } else next({ name: "error404" });
      break;
    case "customers":
    case "customer-detail":
    case "customer-action":
    case "history-registry":
    case "history-update":
    case "contract-registry":
    case "contract-update":
    case "add-sales-follow":
    case "detail-sales-follow":
    case "update-sales-follow":
    case "other-block-registry-detail": // TLI04008
    case "properties-other-register": // TLI04007
    case "property-update": // TLI04003
    case "property-block-update": // TLI04005
    case "mail-reference":
      if ([USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(userInfo.role)) {
        next();
      } else next({ name: "error404" });
      break;
    case "freemail-setting":
      if (
        userInfo.role === USER_ROLE.ADMIN ||
        (userInfo.role === USER_ROLE.EDITOR &&
          userInfo.follow_available_flag === true)
      ) {
        next();
      } else next({ name: "error404" });
      break;
    case "new-mails":
    case "sending-plan":
    case "new-mails-finish-confirm":
    case "new-mails-main":
      if (
        userInfo.role === USER_ROLE.ADMIN ||
        (userInfo.role === USER_ROLE.EDITOR &&
          userInfo.follow_available_flag === true)
      ) {
        next();
      } else next({ name: "error404" });
      break;
    case "aggregate-v2":
      if (userInfo.role === USER_ROLE.ADMIN) {
        next();
      } else next({ name: "error404" });
      break;
    default:
      next();
      break;
  }
});
function createTitle(to) {
  // For constant title != 件レコメンドAIシステム, set title in meta tag
  // For title varies by data, set title in component
  return to.meta.title || "新築レコメンドAIシステム";
}
