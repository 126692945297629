<template>
  <div @click="focusInput()">
    <select ref="select2" :class="subClass" :disabled="disabled">
      <option></option>
      <option
        v-for="(item, index) in selections"
        :key="index"
        :value="item.value">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script>
import $ from "jquery";
import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.full.min";

export default {
  name: "SimpleSelect2",
  props: {
    modelValue: { type: [String, Number], default: "" },
    subClass: { type: String, default: "" },
    options: { type: Array, default: () => [] },
    longOptions: { type: Array, default: () => [] },
    placeholder: { type: String, default: "" },
    noResultText: { type: String, default: "該当データがありません。" },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  computed: {
    selections() {
      if (this.longOptions.length) return this.longOptions;
      const result = [];
      for (const text of this.options) {
        result.push({ label: text, value: text });
      }
      return result;
    },
  },
  watch: {
    modelValue: "triggerChange",
  },
  mounted() {
    // Initialize Select2
    const text = this.noResultText;
    $(this.$refs.select2).select2({
      placeholder: this.placeholder,
      language: {
        noResults: function (_params) {
          return text;
        },
      },
    });
    this.triggerChange(this.modelValue);

    // Listen for changes and emit an event
    $(this.$refs.select2).on("change", (event) => {
      this.$emit("update:modelValue", event.target.value);
    });
  },
  beforeUnmount() {
    // Destroy Select2 when the component is unmounted
    $(this.$refs.select2).select2("destroy");
  },
  methods: {
    focusInput() {
      // Auto focus to input when click select
      const selectInput = document.querySelector("input.select2-search__field");
      if (selectInput) selectInput.focus();
    },
    triggerChange(newValue) {
      // Trigger Select2 change to newValue
      $(this.$refs.select2).val(newValue).trigger("change");
    },
  },
};
</script>

<style>
.field-error .select2-selection--single .select2-selection__rendered {
  background-color: #ffe2e2 !important;
}
</style>
