<template>
  <div class="wrapper">
    <SideBarTop />
    <SideBarLeft />
    <div class="content-wrapper">
      <section class="content py-3">
        <div class="container-fluid">
          <div class="card">
            <div class="card-body minh-520">
              <div class="row mb-4">
                <div class="col-md-7">
                  <h3 class="card-title-cus">中古物件</h3>
                </div>
              </div>
              <FilterSalesForce :key="$route.query" @reload="fetchData" />
              <div v-if="items && items.length > 0" class="wrap-ct">
                <TableProperties :items="items" @sort="handlerClick" />
                <div class="custom-pagi my-4">
                  <PaginateInterface
                    :key="`paginate_${page}_${size}_${total}`"
                    :initial-page="page"
                    :pages="pages"
                    :size="size" />
                </div>
                <PaginateInfo :page="page" :size="size" :total="total" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <ModalLayout :id="'modalFollow'">
    <div class="fs-16 my-4">条件に該当する検索結果がありません。</div>
    <div class="text-center">
      <Button
        class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
        text="閉じる "
        data-dismiss="modal" />
    </div>
  </ModalLayout>
</template>
<script>
import $ from "jquery";
import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import PaginateInterface from "/src/components/commons/PaginateInterface.vue";
import SideBarLeft from "/src/components/layouts/SidebarLeft.vue";
import SideBarTop from "/src/components/layouts/SidebarTop.vue";
import FilterSalesForce from "/src/components/salesforce-properties/FilterSalesforce.vue";
import TableProperties from "/src/components/salesforce-properties/TableProperties.vue";
import { getParamsUrl } from "/src/service/utility.service";
import { getSalesForceProPerties } from "/src/frontend/src/service/salesforce.js";
export default {
  components: {
    Button,
    ModalLayout,
    PaginateInfo,
    SideBarLeft,
    SideBarTop,
    FilterSalesForce,
    TableProperties,
    PaginateInterface,
  },
  data() {
    return {
      page: 1,
      size: 50,
      pages: 0,
      items: [],
      total: 0,
      desc: false,
      order_by: null,
    };
  },
  watch: {
    "$route.query": {
      handler: function () {
        this.fetchData();
      },
      immediate: true,
    },
  },
  created() {
    this.fetchInitialData();
  },
  updated() {
    this.fetchInitialData();
  },
  methods: {
    showPopUP() {
      $("#modalFollow").modal();
    },
    fetchInitialData() {
      if (location.search == "") {
        this.$router.push(`${location.pathname}?page=1`);
      }
    },
    fetchSFP(params) {
      getSalesForceProPerties({
        ...params,
        recommend_disabled_flag: false,
      }).then((res) => {
        this.total = res.data["total"];
        this.pages = res.data["pages"];
        this.items = res.data["items"];
        if (res.data["items"].length == 0) this.showPopUP();
      });
    },
    fetchData() {
      if (location.search != "") {
        let params = getParamsUrl();
        this.page = params["page"] ? parseInt(params["page"]) : this.page;
        params = {
          ...params,
          page: this.page,
          desc: this.desc,
          order_by: this.order_by,
        };
        this.fetchSFP(params);
      }
    },
    handlerClick(event) {
      this.order_by = event.order_by;
      this.desc = event.desc;
      let params = getParamsUrl();
      params = { ...params, page: this.page, ...event };
      this.fetchSFP(params);
    },
  },
};
</script>
