<!-- eslint-disable no-unused-vars -->
<template>
  <div
    :id="id"
    class="tab-pane fade show active"
    role="tabpanel"
    aria-labelledby="nav-1-tab">
    <div class="mx-4 my-3">
      <div class="card-body px-0">
        <div class="row align-items-center">
          <div class="col-6">
            <label class="fs-18 mb-0">送信一覧</label>
          </div>
          <div class="col-6 text-right">
            <router-link
              class="btn btn-cus btn-blue-light px-3"
              :to="{ name: 'sending-plan' }">
              メール作成
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="isShow" class="wapper-table">
        <div class="border">
          <div class="custom-table">
            <table class="table table-striped table-border-vertical mb-0">
              <thead>
                <tr class="text-nowrap text-center">
                  <th class="w-400 minw-300">紹介物件</th>
                  <th class="w-150">
                    <div class="sorted">
                      送信日
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: arrowUp === desc[0] }"
                          @click="sortData('up', 0, 'plan_send_date')"
                          ><i class="fas fa-caret-up"></i></span
                        ><span
                          class="sorting_desc"
                          :class="{ active: arrowDown === desc[0] }"
                          @click="sortData('down', 0, '-plan_send_date')"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      ステータス
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: arrowUp === desc[1] }"
                          @click="sortData('up', 1, 'num_of_success_sending')"
                          ><i class="fas fa-caret-up"></i></span
                        ><span
                          class="sorting_desc"
                          :class="{ active: arrowDown === desc[1] }"
                          @click="
                            sortData('down', 1, '-num_of_success_sending')
                          "
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      送信件数
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: arrowUp === desc[2] }"
                          @click="sortData('up', 2, 'num_of_mails_order')"
                          ><i class="fas fa-caret-up"></i></span
                        ><span
                          class="sorting_desc"
                          :class="{ active: arrowDown === desc[2] }"
                          @click="sortData('down', 2, '-num_of_mails_order')"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="sorted">
                      開封率
                      <div class="sorting">
                        <span
                          class="sorting_asc"
                          :class="{ active: arrowUp === desc[3] }"
                          @click="sortData('up', 3, 'opened_rate')"
                          ><i class="fas fa-caret-up"></i></span
                        ><span
                          class="sorting_desc"
                          :class="{ active: arrowDown === desc[3] }"
                          @click="sortData('down', 3, '-opened_rate')"
                          ><i class="fas fa-caret-down"></i
                        ></span>
                      </div>
                    </div>
                  </th>
                  <th class="text-center w-150">修 正</th>
                  <th class="text-center w-150">中 止</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <router-link
                      v-if="item.num_of_success_sending > 0"
                      class="txt-underline"
                      :to="{
                        name: 'new-mails-finish-confirm',
                        params: {
                          property_id: item.property_id,
                          plan_send_date: item.plan_send_date,
                        },
                      }">
                      {{ item.property_name }}
                    </router-link>
                    <span v-if="item.num_of_success_sending <= 0">{{
                      item.property_name
                    }}</span>
                  </td>
                  <td class="text-nowrap text-right">
                    {{
                      func.formatDate(item.plan_send_date, {
                        dateStyle: "long",
                      })
                    }}
                  </td>
                  <td class="text-center">
                    {{ item.num_of_success_sending > 0 ? "済" : "未" }}
                  </td>
                  <td class="text-right">
                    {{ func.formatNumber(item.num_of_mails_order) }}
                  </td>
                  <td class="text-right">{{ item.opened_rate + "%" }}</td>
                  <td class="text-center">
                    <router-link
                      v-if="item.num_of_success_sending <= 0"
                      class="btn btn-cus btn-blue-light"
                      :to="{
                        name: 'update-mail-sent',
                        params: {
                          property_id: item.property_id,
                          plan_send_date: item.plan_send_date,
                        },
                      }">
                      <img src="@/assets/img/icons/ic-pen.png" alt="edit" />
                    </router-link>
                  </td>
                  <td class="text-center">
                    <button
                      v-if="item.num_of_success_sending <= 0"
                      class="btn btn-cus btn-pink"
                      data-toggle="modal"
                      data-target="#confirmModal"
                      @click="showPopUP(item.property_id, item.plan_send_date)">
                      <img
                        src="@/assets/img/icons/ic-trasts-black.png"
                        alt="delete" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="custom-pagi my-4">
          <Paginate
            :initial-page="page"
            :page="page"
            :pages="pages"
            :total="total"
            :size="size"
            :click-handler="loadPage" />
        </div>
        <PaginateInfo :page="page" :total="total" :size="size" />
      </div>
    </div>
    <ModalConfirmFollow
      :id="'modalFollowMail'"
      :message="'直近6か月以内に送付した新着メールはありません。'" />
  </div>
  <ModalLayout :id="'modalDelete'">
    <div class="fs-16 my-4">
      送信予定のメールを削除します。
      <br />
      よろしいですか？
    </div>
    <div class="text-center">
      <Button
        class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
        text="は い"
        data-dismiss="modal"
        @click="deleteMail" />
      <Button
        class="btn btn-cus btn-grey mx-2 w-125 fs-14"
        text="いいえ"
        data-dismiss="modal"
        @click="showAlert(' 処理を中止しました。')" />
    </div>
  </ModalLayout>
</template>

<script>
import $ from "jquery";
import Paginate from "/src/components/commons/Paginate.vue";
import PaginateInfo from "/src/components/commons/PaginateInfo.vue";
import {
  getListMailContent,
  deleteNewMail,
} from "/src/frontend/src/service/new-mail.service.js";
import ModalConfirmFollow from "/src/components/modals/ModalConfirmFollow.vue";
import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";
import { mapActions } from "vuex";
export default {
  name: "TabListMail",
  components: {
    Paginate,
    PaginateInfo,
    ModalConfirmFollow,
    ModalLayout,
    Button,
  },
  props: {
    id: { type: String, default: "nav-1" },
  },
  data() {
    return {
      page: 1,
      size: 50,
      pages: 1,
      total: 0,
      items: [],
      isShow: false,
      arrowUp: false,
      arrowDown: false,
      desc: [null, null, null, null],
      property_id: null,
      plan_send_date: null,
      order_by: "-plan_send_date",
    };
  },
  watch: {
    $route: {
      handler() {
        if (location.search == "") {
          this.$router.go();
        }
      },
    },
  },
  created() {
    if (location.search !== "") {
      this.page = this.getPageFromUrl(location.search);
    }
    this.$router.replace(`${"new-mails"}?page=${this.page}`);
    this.initData();
  },
  methods: {
    loadPage(numpage) {
      this.$router.replace(`${"new-mails"}?page=${numpage}`);
      getListMailContent({
        page: numpage,
        size: this.size,
        order_by: this.order_by,
      }).then((data) => {
        this.page = numpage;
        this.total = data.total;
        this.pages = data.pages;
        this.items = data.items;
        if (this.items.length != 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
          $("#modalFollowMail").modal();
        }
      });
    },
    showPopUP(property_id, plan_send_date) {
      this.property_id = property_id;
      this.plan_send_date = plan_send_date;
      $("#modalDelete").modal();
    },
    deleteMail() {
      deleteNewMail({
        property_id: this.property_id,
        plan_send_date: this.plan_send_date,
        pre_submit: false,
      }).then((_) => {
        this.initData();
        this.showAlert("メールを削除しました。");
      });
      return;
    },
    ...mapActions("alertMessage", ["showAlert"]),
    sortData(type, index, field_sort) {
      this.desc.fill(null);
      if (type === "up") {
        this.desc[index] =
          this.arrowUp !== this.desc[index] ? this.arrowUp : this.desc[index];
        this.arrowDown = !this.arrowUp;
      } else if (type === "down") {
        this.desc[index] =
          this.arrowDown !== this.desc[index]
            ? this.arrowDown
            : this.desc[index];
        this.arrowUp = !this.arrowDown;
      }
      getListMailContent({
        page: this.page,
        size: this.size,
        order_by: field_sort,
      }).then((data) => {
        this.order_by = field_sort;
        this.total = data.total;
        this.pages = data.pages;
        this.items = data.items;
      });
    },
    initData() {
      getListMailContent({
        page: this.page,
        size: this.size,
        order_by: this.order_by,
      }).then((data) => {
        this.total = data.total;
        this.pages = data.pages;
        this.items = data.items;
        if (this.items.length != 0) {
          this.isShow = true;
        } else {
          this.isShow = false;
          $("#modalFollowMail").modal();
        }
      });
    },
    getPageFromUrl(url) {
      const params = new URLSearchParams(url.split("?")[1]);
      const pageNumber = parseInt(params.get("page"), 10);
      return pageNumber;
    },
  },
};
</script>
