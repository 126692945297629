<template>
  <div
    id="nav-1"
    class="tab-pane fade show active"
    role="tabpanel"
    aria-labelledby="nav-3-tab">
    <div class="mx-4">
      <div class="cus-filter my-0 border-0">
        <div class="card-body custom-form px-0">
          <div class="row align-items-center">
            <div class="col-12 col-lg-3 col-xl-2 my-2">
              <label class="mb-0">物 件</label>
            </div>
            <div class="col-12 col-lg-8 col-xl-2 my-2">
              <DisabledSelect2
                v-if="isUpdate"
                :key="propertyLabel"
                :text="propertyLabel" />
              <SelectProperty
                v-else
                v-model="propertyId"
                :params="{ location_of_original_data: 1 }" />
            </div>
            <div class="col-12 col-lg-3 col-xl-1 my-2 text-xl-center">
              <label class="text-nowrap mb-0">表示区画</label>
            </div>
            <div class="col-12 col-lg-8 col-xl-2 my-2">
              <DisabledSelect2
                v-if="isUpdate"
                :key="blockLabel"
                :text="blockLabel" />
              <SelectBlock
                v-else
                v-model="block"
                :disabled="!propertyId"
                :get-url="true"
                :show-layout="true"
                :property-id="propertyId"
                :params="{ image_link_not_null: true }" />
            </div>
            <div class="col-12 col-lg-3 col-xl-1 my-2 text-xl-center">
              <label class="text-nowrap mb-0">送信日</label>
            </div>
            <div class="col-12 col-lg-8 col-xl-2 my-2">
              <p class="text-nowrap mb-0">
                {{ func.formatDate(planSentDate, { dateStyle: "long" }) }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-3 col-xl-2 my-2">
              <label>フリーテキスト</label>
            </div>
            <div class="col-lg-8 my-2">
              <textarea
                v-model.trim="description"
                type="text"
                class="form-control"
                rows="6"
                placeholder=""
                @input="if (!description) description = null;"></textarea>
            </div>
          </div>
          <div class="text-center">
            <FunctionalButton
              v-if="!isUpdate && isValid"
              class="btn-blue-light px-3 m-2 w-105"
              text="絞り込み"
              @click="
                isValid &&
                  $emit('active-customers', {
                    propertyId,
                    blockId,
                  })
              " />
            <FunctionalButton
              v-if="isValid"
              class="btn-blue-light px-3 m-2"
              text="送信メールプレビュー"
              @click="isValid && gotoPreview()" />

            <FunctionalButton
              v-if="isUpdate"
              class="btn-blue-light px-3 m-2"
              text="確定・送信"
              :is-valid="originalDescription !== description"
              @click="showConfirmModal(originalDescription !== description)" />
            <FunctionalButton
              v-else
              class="btn-blue-light px-3 m-2"
              text="確定・送信"
              :is-valid="hasCustomers"
              @click="showConfirmModal(hasCustomers)" />
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm
      id="modal-confirm"
      message="この内容でメールを送信します。"
      @no="handleClose()"
      @yes="handleConfirm()" />
  </div>
</template>

<script>
import {
  createNewMails,
  getNewMailContent,
  updateNewMailContent,
} from "/src/service/new-mail.service.js";
import { mapState, mapActions } from "vuex";
const { apiMessage } = require("/src/assets/validation.json");

import SelectProperty from "/src/components/customers/action/form-fields/SelectProperty.vue";
import SelectBlock from "/src/components/customers/action/form-fields/SelectBlock.vue";
import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";
import DisabledSelect2 from "./DisabledSelect2.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";

import $ from "jquery";

export default {
  components: {
    SelectProperty,
    SelectBlock,
    FunctionalButton,
    DisabledSelect2,
    ModalConfirm,
  },
  props: {
    hasCustomers: { type: Boolean, default: false },
    isUpdate: { type: Boolean, default: false },
  },
  emits: ["active-customers", "inactive-customers"],
  data() {
    const now = new Date();
    const nowStr = now.toLocaleDateString("en-CA", { timeZone: "Asia/Tokyo" });
    return {
      propertyId: null,
      propertyLabel: "",
      block: null,
      blockLabel: "",
      description: null,
      originalDescription: null,
      planSentDate: `${nowStr}`,
    };
  },
  computed: {
    ...mapState("confirmapi", ["message"]),
    isValid() {
      const result = this.propertyId !== null && this.block !== null;
      this.$emit("inactive-customers");
      return result;
    },
    blockId() {
      if (!this.block) return null;
      return Number(this.block.split("|")[0]);
    },
    referencePageUrl() {
      if (!this.block) return null;
      return String(this.block.split("|")[1]);
    },
  },
  watch: {
    message() {
      if (
        this.message == "もう一度絞り込みをクリックして、確認してください。"
      ) {
        this.$emit("inactive-customers");
      }
    },
  },
  created() {
    if (this.isUpdate) {
      this.propertyId = this.$route.params.property_id;
      this.planSentDate = this.$route.params.plan_send_date;
      this.submitUpdate(true).catch(() => {
        this.$router.replace({ name: "new-mails-finish-confirm" });
      });
      this.fetchInitialData();
    }
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    async fetchInitialData() {
      const res = await getNewMailContent({
        property_id: this.propertyId,
        plan_send_date: this.planSentDate,
      });
      const blockName = res.data.property_block?.block_name || "";
      const blockLayout = res.data.property_block?.layout_type_txt || "";
      const blockId = res.data.property_block?.block_id || "";
      const pageUrl = res.data.property_block?.reference_page_url || "";

      this.block = `${blockId}|${pageUrl}`;
      this.description = res.data.free_words;
      this.originalDescription = res.data.free_words;
      this.propertyLabel = res.data.property?.name || "";
      this.blockLabel = `${blockName}（${blockLayout}）`;

      this.$emit("active-customers", {
        propertyId: this.propertyId,
        blockId: blockId,
      });
    },
    gotoPreview() {
      const route = this.$router.resolve({
        name: "mail-reference",
        params: { uuid: this.referencePageUrl },
        query: { description: this.description },
      });
      window.open(route.href, "_blank");
    },
    async submitCreate() {
      const res = await createNewMails({
        property_id: this.propertyId,
        block_id: this.blockId,
        plan_send_date: this.planSentDate,
        free_words: this.description,
      });
      return res;
    },
    async submitUpdate(pre_submit) {
      await updateNewMailContent({
        property_id: this.propertyId,
        free_words: this.description,
        plan_send_date: this.planSentDate,
        pre_submit: pre_submit,
      });
      // Reload data after update
      this.originalDescription = this.description;
    },
    showConfirmModal(isValidForm) {
      if (!isValidForm) return;
      $(`#modal-confirm`).modal("show");
    },
    handleClose() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
    async handleConfirm() {
      if (this.isUpdate) {
        await this.submitUpdate(false);
        this.showAlert("メール送信処理を受付けました。");
      } else {
        const res = await this.submitCreate();
        if (res.status != 200) return;
        if (this.hasBulkError(res.data)) return;
        await this.showAlert("メール送信処理を受付けました。");
        this.func.navigateBack(this.$router, "new-mails-main");
      }
    },
    hasBulkError(response) {
      const errors = response.errors || [];

      for (const err of errors) {
        const errorMsg = err.msg;
        if (errorMsg) {
          this.$store.commit("confirmapi/setConfirm", {
            status: true,
            mes: errorMsg,
          });
          return true;
        }
      }
      return false;
    },
  },
};
</script>
