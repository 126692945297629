<template>
  <FieldSelect2
    v-if="getUrl"
    :key="`select_block_addition_${propertyId}_${blockId}_${blocks.length}`"
    v-model="blockId"
    class="custom-select2 asset-name-type asset-name-type-1 w-100"
    :long-options="blocks" />

  <FieldSelect2
    v-else
    :key="`select_block_${propertyId}_${blockId}_${blocks.length}`"
    v-model.number="blockId"
    class="custom-select2 asset-name-type asset-name-type-1 w-100"
    :long-options="blocks" />
</template>

<script>
import { getPropertyBlocks } from "/src/service/properties.service.js";

import FieldSelect2 from "/src/components/utilitys/inputs/FieldSelect2.vue";

export default {
  components: {
    FieldSelect2,
  },
  props: {
    modelValue: { type: [String, Number], default: null },
    propertyId: { type: [String, Number], default: null },
    params: { type: Object, default: () => ({}) },
    getUrl: { type: Boolean, default: false },
    showLayout: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      blockId: this.modelValue,
      blocks: [],
    };
  },
  watch: {
    modelValue(newValue) {
      this.blockId = newValue;
    },
    blockId(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    propertyId(newValue) {
      if (newValue) this.fetchBlocks(newValue);
      this.blockId = null;
    },
  },
  created() {
    if (this.propertyId) this.fetchBlocks(this.propertyId);
  },
  methods: {
    async fetchBlocks(propertyId) {
      this.blocks = [];
      const params = { all: true, property_id: propertyId, ...this.params };
      const response = await getPropertyBlocks(params);

      const allBlocks = response.data.items;
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      allBlocks.sort((a, b) => collator.compare(a.block_name, b.block_name));
      allBlocks.forEach((el) => {
        let selectValue = el.block_id;
        if (this.getUrl)
          selectValue = `${el.block_id}|${el.reference_page_url}`;

        let selectLabel = el.block_name;
        if (this.showLayout)
          selectLabel = `${el.block_name}（${el.layout_type_txt}）`;

        this.blocks.push({ value: selectValue, label: selectLabel });
      });
    },
  },
};
</script>
