<template>
  <div class="custom-table custom-table-3">
    <table class="table border">
      <tbody>
        <tr class="active">
          <td class="col-6 col-sm-4">物件区分</td>
          <td>
            {{
              returnTextButton(
                items.block_classification,
                items.property.completion_date
              )
            }}
          </td>
        </tr>
        <tr>
          <td>タイプ</td>
          <td>{{ items.block_type }}</td>
        </tr>
        <tr class="active">
          <td>間取り</td>
          <td>
            {{
              items.layout_type
                ? func.convertEnum(items.layout_type, BLOCK_LAYOUT_TYPE)
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>専有面積</td>
          <td>
            {{
              items.area
                ? func.formatNumber(items.area, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>バルコニー面積</td>
          <td>
            {{
              items.balcony_area
                ? func.formatNumber(items.balcony_area, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>階 数</td>
          <td>
            {{ items.floor_number ? items.floor_number + "階" : "" }}
          </td>
        </tr>
        <tr class="active">
          <td>税込総額（販売価格）</td>
          <td>
            {{ items.price ? func.formatNumber(items.price) + "円" : "" }}
          </td>
        </tr>
        <tr class="active">
          <td>管理費</td>
          <td>
            {{
              items.management_fee
                ? func.formatNumber(items.management_fee) + "円"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>修繕積立金</td>
          <td>
            {{
              items.repair_reserve_fee
                ? func.formatNumber(items.repair_reserve_fee) + "円"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>引渡予定日</td>
          <td>
            {{
              items.delivery_date
                ? func.formatDate(items.delivery_date, {
                    dateStyle: "long",
                  })
                : ""
            }}
          </td>
        </tr>

        <tr class="active">
          <td>ルーフバルコニー有無</td>
          <td>
            {{ checkXO(items.roof_balcony_exist_flag) }}
          </td>
        </tr>
        <tr class="active">
          <td>ルーフバルコニー面積</td>
          <td>
            {{
              items.area_of_roof_balcony
                ? func.formatNumber(items.area_of_roof_balcony, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>ルーフバルコニー使用料</td>
          <td>
            {{
              items.usage_fee_of_roof_balcony
                ? func.formatNumber(items.usage_fee_of_roof_balcony) + "円"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>専用庭有無</td>
          <td>
            {{ checkXO(items.private_garden_exist_flag) }}
          </td>
        </tr>
        <tr class="active">
          <td>専用庭面積</td>
          <td>
            {{
              items.area_of_private_garden
                ? func.formatNumber(items.area_of_private_garden, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>専用庭使用料</td>
          <td>
            {{
              items.usage_fee_of_private_garden
                ? func.formatNumber(items.usage_fee_of_private_garden) + "円"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>専用テラス有無</td>
          <td>
            {{ checkXO(items.private_terrace_exist_flag) }}
          </td>
        </tr>
        <tr class="active">
          <td>専用テラス面積</td>
          <td>
            {{
              items.area_of_private_terrace
                ? func.formatNumber(items.area_of_private_terrace, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + "㎡"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>専用テラス使用料</td>
          <td>
            {{
              items.usage_fee_of_private_terrace
                ? func.formatNumber(items.usage_fee_of_private_terrace) + "円"
                : ""
            }}
          </td>
        </tr>
        <tr class="active">
          <td>最上階</td>
          <td>{{ checkXO(items.top_floor) }}</td>
        </tr>
        <tr class="active">
          <td>家具付き</td>
          <td>{{ checkXO(items.furnished) }}</td>
        </tr>
        <tr class="active">
          <td>備 考</td>
          <td>
            <span style="white-space: pre-wrap">{{ items.block_note }}</span>
          </td>
        </tr>
        <tr>
          <td>レコメンド対象除外フラグ</td>
          <td>{{ items.recommend_stop_flag ? "✓" : "" }}</td>
        </tr>
        <tr>
          <td>登録日時</td>
          <td>
            {{
              func.formatDate(items.created_at, {
                dateStyle: "long",
                timeStyle: "short",
              })
            }}
          </td>
        </tr>
        <tr>
          <td>更新日時</td>
          <td>
            {{
              func.formatDate(items.updated_at, {
                dateStyle: "long",
                timeStyle: "short",
              })
            }}
          </td>
        </tr>
        <tr>
          <td>更新者</td>
          <td>{{ items?.updated_by_user?.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { returnTextButton } from "/src/components/recommend-properties/commons/utils/format.js";
import { checkXO } from "/src/components/dynamic-block/utils.js";
import { BLOCK_LAYOUT_TYPE } from "/src/constants";
export default {
  props: { items: { type: Object, default: () => {} } },
  data() {
    return { BLOCK_LAYOUT_TYPE, checkXO, returnTextButton };
  },
};
</script>
