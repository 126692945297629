<template>
  <div class="row align-items-center">
    <div class="col-12 col-md-4 col-xl-3 my-2">
      <div class="form-check custom-checkbox">
        <input
          id="option1"
          v-model="typeAsset"
          class="form-check-input check-type"
          type="radio"
          name="distinguish"
          :value="type.property"
          checked />
        <label for="option1">他社媒介／新築</label>
      </div>
    </div>
    <div class="col-12 col-md-5 col-xl-3 my-2">
      <div class="form-check custom-checkbox">
        <input
          id="option2"
          v-model="typeAsset"
          class="form-check-input check-type"
          type="radio"
          name="distinguish"
          :value="type.salesForce" />
        <label for="option2">中 古</label>
      </div>
    </div>
  </div>
</template>

<script>
import { PROPERTY_TYPE } from "/src/frontend/src/constants.js";

export default {
  props: {
    modelValue: { type: Number, default: null },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      type: {
        property: PROPERTY_TYPE.PROPERTY,
        salesForce: PROPERTY_TYPE.SALESFORCE_PROPERTY,
      },
    };
  },
  computed: {
    typeAsset: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
