<template>
  <div class="card-body minh-520">
    <div class="row mb-4">
      <div class="col-md-12">
        <h3 class="card-title-cus">ユーザー新規登録</h3>
      </div>
    </div>
    <div class="wrap-ct">
      <div class="my-4 mx-3 mx-md-5">
        <div class="custom-form col-xl-9">
          <form>
            <FieldText
              ref="name"
              v-model="user.name"
              type="text"
              label="名 前"
              :mandatory="true"
              :error="nameMsg"
              :auto-focus="true"
              @focusout="
                removeEmptyNameError();
                handleNameFocusout();
              " />
            <FieldText
              v-model="user.email"
              type="email"
              label="メールアドレス"
              :mandatory="true"
              :error="emailMsg"
              @focusout="removeEmptyEmailError()" />
            <FieldSingleSelect
              v-model="user.role"
              label="ユーザー属性"
              :check-options="checkOptions" />
            <div v-if="user.role != 1" class="row align-items-center">
              <div class="col-12 col-md-4 my-2">担 当</div>
              <div class="col-12 col-md-7 my-2">
                <div class="form-check custom-checkbox ml-3 d-inline-block">
                  <input
                    id="option4"
                    v-model="user.follow_available_flag"
                    class="form-check-input"
                    type="checkbox" />
                  <label for="option4"></label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="my-4 text-center">
              <Button
                class-list-extend="btn-blue-light m-2 ml-md-0 w-150"
                text="登 録"
                @click="showConfirmModaluserChange" />
              <Button
                class-list-extend="btn-grey m-2 w-150"
                text="キャンセル"
                data-toggle="modal"
                data-target="#confirmExist" />
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        id="confirmModalUserChange"
        message="ユーザー情報を登録します。"
        @yes="submitForm()"
        @no="abortProcess()" />
      <ModalConfirm
        id="confirmExist"
        message="ユーザー情報の登録をキャンセルします。"
        @yes="navigateToUserList()"
        @no="abortProcess()" />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";
import FieldText from "/src/components/utilitys/inputs/FieldText.vue";
import FieldSingleSelect from "/src/components/utilitys/inputs/FieldSingleSelect.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalConfirm from "/src/components/modals/ModalConfirm.vue";
import {
  addUser,
  check_duplicate,
  textNormalize,
} from "/src/service/users.service.js";
import { USER_ROLE } from "/src/constants";
const {
  regex,
  userMessage,
  apiMessage,
} = require("/src/assets/validation.json");

export default {
  name: "UserAdd",
  components: {
    FieldText,
    FieldSingleSelect,
    Button,
    ModalConfirm,
  },
  data() {
    return {
      emailMsg: "",
      nameMsg: "",
      user: {
        name: "",
        email: "",
        follow_available_flag: false,
        role: 1,
      },
      checkOptions: [
        {
          label: "管理者",
          value: 7,
        },
        {
          label: "編集ユーザー",
          value: 3,
        },
        {
          label: "閲覧のみユーザー",
          value: 1,
        },
      ],
    };
  },
  computed: {
    isValidForm() {
      return (
        this.emailMsg === "" && this.user.name != "" && this.user.email != ""
      );
    },
  },
  watch: {
    "user.role": {
      handler: function (newObj) {
        console.log(newObj);
        if (newObj == 1) this.user.follow_available_flag = false;
      },
    },
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    emailValidate() {
      const reg = new RegExp(regex.email);
      if (!reg.test(this.user.email)) {
        return false;
      }
      return true;
    },
    emptyEmailValidate() {
      if (this.user.email == "") {
        this.emailMsg = userMessage.REQUIRED;
        return false;
      }
      this.emailMsg = "";
      return true;
    },
    removeEmptyEmailError() {
      if (this.user.email != "") {
        this.emailMsg = "";
      }
    },
    removeEmptyNameError() {
      if (this.user.name != "") {
        this.nameMsg = "";
      }
    },
    nameValidate() {
      if (this.user.name === "") {
        this.nameMsg = userMessage.REQUIRED;
      } else this.nameMsg = "";
    },
    handleNameFocusout() {
      // example half width katakana: ｶﾀｶﾅ
      this.user.name = textNormalize(this.user.name);
    },
    showConfirmModaluserChange() {
      this.emptyEmailValidate();
      this.nameValidate();
      if (this.nameMsg || this.emailMsg) {
        return;
      } else if (!this.emailValidate()) {
        this.$store.commit("confirmapi/setConfirm", {
          status: true,
          mes: "処理を中止しました。メールアドレスに使用しているテキストで利用不能な文字が存在します。",
        });
      } else {
        check_duplicate({ email: this.user.email, name: this.user.name }).then(
          (res) => {
            $("#confirmModalUserChange").modal("show");
          }
        );
      }
    },
    navigateToUserList() {
      this.$router.push({ name: `users` });
      this.showAlert("ユーザー情報の登録をキャンセルしました。");
    },
    submitForm() {
      this.emailValidate();
      this.nameValidate();
      let body = {
        name: this.user.name,
        email: this.user.email,
        role: this.user.role | USER_ROLE.VIEWER,
        follow_available_flag: this.user.follow_available_flag,
      };
      addUser(body).then((ok) => {
        if (ok) {
          this.showAlert("ユーザー情報を登録しました。");
          this.$router.replace({ name: `users` });
        }
      });
    },
    abortProcess() {
      this.showAlert(apiMessage.ABORT_PROCESS);
    },
  },
};
</script>
