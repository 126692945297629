<!-- eslint-disable no-irregular-whitespace -->
<template>
  <ScrollTableLayout>
    <table class="table table-striped table-border-vertical">
      <thead>
        <tr class="text-nowrap text-center">
          <th class="w-150">氏 名</th>
          <th class="w-150">フリガナ</th>
          <th class="minw-300">メールアドレス</th>
          <th v-if="mailType === 'regular'">送信日時</th>
          <th v-else>メール送信日</th>
          <th v-if="mailType === 'regular'">紹介物件1</th>
          <th v-if="mailType === 'regular'">紹介物件2</th>
          <th v-if="mailType === 'regular'">紹介物件3</th>
          <th v-else>紹介物件</th>
          <th class="text-center">送信除外</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in mails" :key="`mail-list-${idx}`">
          <td>{{ item.customer?.full_name_kanji }}</td>
          <td>{{ item.customer?.full_name_furigana }}</td>
          <td class="w-215">{{ item.mail_recipient }}</td>
          <td class="text-right">
            <TimeBlock
              v-if="mailType === 'regular'"
              :date-time-str="item.plan_recommend_at" />
            <div v-else class="text-nowrap">
              {{ func.formatDate(item.plan_send_date, { dateStyle: "long" }) }}
            </div>
          </td>
          <td v-if="mailType === 'regular'">
            {{ getPropertyName(item?.recommend_property_1) }}
          </td>
          <td v-if="mailType === 'regular'" class="w-215">
            {{ getPropertyName(item?.recommend_property_2) }}
          </td>
          <td v-if="mailType === 'regular'">
            {{ getPropertyName(item?.recommend_property_3) }}
          </td>
          <td v-else class="w-215">
            {{ getPropertyName(item?.new_mail_property) }}
          </td>
          <td>
            <div class="form-check custom-checkbox">
              <input
                :id="`check_${idx}`"
                v-model="checkedIds"
                :class="{ 'field-error': error }"
                class="form-check-input mx-auto"
                type="checkbox"
                :value="getMailId(item)"
                :disabled="!isValidMail(item)"
                @click="handleClearError(getMailId(item))" />
              <label :for="`check_${idx}`"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>

<script>
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
import TimeBlock from "/src/components/utilitys/TimeBlock.vue";

export default {
  components: {
    ScrollTableLayout,
    TimeBlock,
  },
  props: {
    mails: { type: Array, default: () => [] },
    mailType: { type: String, required: true },
  },
  data() {
    return {
      checkedIds: [],
      error: false,
    };
  },
  watch: {
    mails() {
      this.error = false;
    },
  },
  methods: {
    isValidMail(item) {
      return item.customer && item.mail_recipient;
    },
    splitTime(dateTimeStr) {
      const result = this.func
        .formatDate(dateTimeStr, { dateStyle: "long", timeStyle: "short" })
        .split(" ");
      if (result.length === 2) return result;
      return ["", ""];
    },
    getMailId(item) {
      return this.mailType === "regular"
        ? item.recommend_mail_id
        : item.new_mail_id;
    },
    getPropertyName(rcm_property) {
      if (!rcm_property) return "";
      if (rcm_property.property) return rcm_property.property.name;
      return rcm_property.salesforce_property?.name;
    },
    handleClearError(itemId) {
      if (this.error) {
        this.error = false;
        this.checkedIds = this.checkedIds.filter((el) => el !== itemId);
      }
    },
  },
};
</script>
