// const LINK_COMPANY = { title: "東急リバブル", link: "#!" };
const LINK_HOME = { title: "新築レコメンド AI システム", link: "#!" };

function createBreadcrumbs(...crumbs) {
  return { breadcrumbs: crumbs };
}

const breadcrumbs = {
  users: createBreadcrumbs(LINK_HOME, {
    title: "ユーザー管理画面",
    link: "",
  }),
  usersDetail: createBreadcrumbs(LINK_HOME, {
    title: "ユーザー情報参照画面",
    link: "",
  }),
  userAdd: createBreadcrumbs(LINK_HOME, {
    title: "ユーザー新規登録画面",
    link: "",
  }),
  userEdit: createBreadcrumbs(LINK_HOME, {
    title: "ユーザー情報変更画面",
    link: "",
  }),
  properties: createBreadcrumbs(LINK_HOME, {
    title: "自社新築・他社物件（棟）検索画面",
    link: "",
  }),
  propertiesUsed: createBreadcrumbs(LINK_HOME, {
    title: "中古物件（区画）検索画面",
    link: "",
  }),
  dynamicProperties: createBreadcrumbs(LINK_HOME, {
    title: "自社新築・他社物件（棟）情報参照画面",
    link: "",
  }),
  registryProperties: createBreadcrumbs(LINK_HOME, {
    title: "他社物件（棟）情報新規登録画面",
    link: "",
  }),
  updateProperties: createBreadcrumbs(LINK_HOME, {
    title: "自社新築・他社物件（棟）情報変更画面",
    link: "",
  }),
  customerSearch: createBreadcrumbs(LINK_HOME, {
    title: "顧客検索画面",
    link: "",
  }),
  customerDetail: createBreadcrumbs(LINK_HOME, {
    title: "顧客情報参照画面",
    link: "",
  }),
  customerAction: createBreadcrumbs(LINK_HOME, {
    title: "履歴一覧画面",
    link: "",
  }),
  historyRegistry: createBreadcrumbs(LINK_HOME, {
    title: "送客履歴登録画面",
    link: "",
  }),
  historyUpdate: createBreadcrumbs(LINK_HOME, {
    title: "送客履歴変更画面",
    link: "",
  }),
  contractRegistry: createBreadcrumbs(LINK_HOME, {
    title: "成約登録画面",
    link: "",
  }),
  contractUpdate: createBreadcrumbs(LINK_HOME, {
    title: "成約情報変更画面",
    link: "",
  }),
  salesFollowRegistry: createBreadcrumbs(LINK_HOME, {
    title: "フォロー履歴登録画面",
    link: "",
  }),
  salesFollowDetail: createBreadcrumbs(LINK_HOME, {
    title: "フォロー履歴詳細画面",
    link: "",
  }),
  salesFollowUpdate: createBreadcrumbs(LINK_HOME, {
    title: "フォロー履歴変更画面",
    link: "",
  }),
  followCustomer: createBreadcrumbs(LINK_HOME, {
    title: "フォロー顧客担当確認画面",
    link: "",
  }),
  inquiryReservationList: createBreadcrumbs(LINK_HOME, {
    title: "問合せ・来場予約一覧",
    link: "",
  }),
  inquiryReservationDetail: createBreadcrumbs(LINK_HOME, {
    title: "問合せ・来場予約詳細画面",
    link: "",
  }),
  followUpAssignment: createBreadcrumbs(LINK_HOME, {
    title: "フォロー顧客担当振り分け画面",
    link: "",
  }),
  DynamicSeflBlockDetail: createBreadcrumbs(LINK_HOME, {
    title: "自社新築・他社物件（区画）情報参照画面",
    link: "",
  }),
  otherBlockRegistryDetail: createBreadcrumbs(LINK_HOME, {
    title: "他社物件（区画）情報新規登録画面",
  }),
  updateBlockDetail: createBreadcrumbs(LINK_HOME, {
    title: "自社新築・他社物件（区画）情報変更画面",
  }),
  freemailSetting: createBreadcrumbs(LINK_HOME, {
    title: "フリーメール設定画面",
  }),
  newMail: createBreadcrumbs(LINK_HOME, {
    title: "新着情報メール管理画面",
  }),
  sendingPlanaggregateV2: createBreadcrumbs(LINK_HOME, {
    title: "新着情報メール送信画面",
  }),
  confirmSendMail: createBreadcrumbs(LINK_HOME, {
    title: "送信済み新着情報メール確認画面",
  }),
  aggregationV2: createBreadcrumbs(LINK_HOME, {
    title: "集計",
  }),
};

export default breadcrumbs;
