<template>
  <ScrollTableLayout>
    <table class="table table-striped table-border-vertical">
      <thead>
        <th class="col-1">優先順位</th>
        <th>氏 名</th>
        <th>フリガナ</th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <td>{{ index + 1 + (page - 1) * size }}</td>
          <td class="w-300">
            <router-link
              v-if="item.customer"
              class="txt-underline"
              target="_blank"
              :class="{ 'act-click': isClick(item.follow_id) }"
              :to="{
                name: 'customer-detail',
                params: { customer_id: item.customer.customer_id },
              }"
              @click="funcClicked(item.follow_id)">
              {{ item.customer ? item.customer.full_name_kanji : "" }}
            </router-link>
          </td>
          <td class="w-300">
            {{ item.customer ? item.customer.full_name_furigana : "" }}
          </td>
        </tr>
      </tbody>
    </table>
  </ScrollTableLayout>
</template>

<script>
import ScrollTableLayout from "/src/components/layouts/ScrollTableLayout.vue";
export default {
  components: {
    ScrollTableLayout,
  },
  props: {
    items: { type: Array, default: () => {} },
    page: { type: Number, default: 0 },
    size: { type: Number, default: 0 },
  },
  data() {
    return {
      listClicked: [],
    };
  },
  methods: {
    funcClicked(idpr) {
      if (idpr && !this.listClicked.includes(idpr)) {
        this.listClicked.push(idpr);
      }
    },
    isClick(idclick) {
      return this.listClicked.includes(idclick);
    },
  },
};
</script>
