<template>
  <div class="overflow-hidden mx-2 mx-sm-0 pt-2">
    <div class="row align-items-center">
      <div class="col-sm-9">
        <div v-if="isShowButton" class="row align-items-center">
          <div class="minw-100 ml-2">
            <div class="form-group">
              <label class="my-0 text-nowrap">ステータス</label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <div class="custom-select2 w-100">
                <FieldSelect2
                  v-model="selectOption"
                  :options="inquiryStatus"
                  :keyid="'value'"
                  :keytext="'label'" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group text-sm-right mr-2">
          <button
            v-if="isShowButton"
            class="btn btn-cus btn-blue-light minw-95"
            :disabled="disableButton"
            @click="showPopUP">
            <span>更 新</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalLayout :id="'modalUpdate'">
    <div class="fs-16 my-4">ステータスをを更新します。よろしいですか？</div>
    <div class="text-center">
      <Button
        class="btn btn-cus btn-blue-light mx-2 w-125 fs-14"
        text="は い"
        data-dismiss="modal"
        @click="updateStatus" />
      <Button
        class="btn btn-cus btn-grey mx-2 w-125 fs-14"
        text="いいえ"
        data-dismiss="modal"
        @click="showAlert(' 処理を中止しました。')" />
    </div>
  </ModalLayout>
</template>
<script>
import $ from "jquery";
import { mapState, mapActions } from "vuex";
import { USER_ROLE } from "@/constants.js";
import { inquiryStatus } from "/src/components/inquiry/const.js";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import Button from "/src/components/utilitys/buttons/Button.vue";
import ModalLayout from "/src/components/layouts/ModalLayout.vue";
import { updateInquiryStatus } from "/src/service/inquiry.service.js";
export default {
  components: {
    FieldSelect2,
    ModalLayout,
    Button,
  },
  props: { data: { type: Object, default: () => {} } },
  data() {
    return {
      selectOption: this.data.follow_status,
      inquiryStatus,
      isDisable: this.data.follow_status,
    };
  },
  computed: {
    ...mapState("user", ["user_object"]),
    isShowButton() {
      if (this.user_object.role == 3) {
        return this.user_object.follow_available_flag == 1;
      } else {
        return [USER_ROLE.ADMIN].includes(this.user_object.role);
      }
    },
    disableButton() {
      return this.isDisable == this.stringToBoolean(this.selectOption);
    },
  },
  methods: {
    stringToBoolean(str) {
      if (typeof str === "boolean") {
        return str;
      }
      if (typeof str === "string") {
        return str.toLowerCase() === "true";
      }
    },
    isEditables() {
      return [USER_ROLE.EDITOR, USER_ROLE.ADMIN].includes(
        this.user_object.role
      );
    },
    ...mapActions("alertMessage", ["showAlert"]),
    getInquiryId() {
      const url = window.location.href;
      const parts = url.split("/");
      const uuid = parts[parts.length - 1];
      return uuid;
    },
    showPopUP() {
      $("#modalUpdate").modal();
    },
    updateStatus() {
      this.showAlert("ステータスを更新しました。");
      const inquiryId = this.getInquiryId();
      updateInquiryStatus(inquiryId, this.selectOption).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
