<template>
  <div class="row">
    <div class="col-md-4">
      <h3 class="card-title-cus mb-3">{{ title }}</h3>
    </div>
    <div class="col-md-8">
      <div class="text-right">
        <FunctionalButton
          v-if="$route.name !== 'customers'"
          class="btn-blue-light px-3 m-2 my-sm-0"
          text="顧客検索"
          @click="$router.push({ name: 'customers' })" />

        <FunctionalButton
          v-if="$route.name !== 'customer-action'"
          class="btn-blue-light px-3 m-2 my-sm-0"
          text="履歴一覧"
          @click="$router.push({ name: 'customer-action' })" />

        <FunctionalButton
          v-if="canCloseTab"
          class="btn-blue-light px-3 m-2 my-sm-0"
          text="閉じる"
          @click="closeTab()" />
        <FunctionalButton
          v-else
          class="btn-blue-light px-3 m-2 my-sm-0"
          text="戻 る"
          @click="$router.go(-1)" />
      </div>
    </div>
  </div>
</template>

<script>
import FunctionalButton from "/src/components/utilitys/buttons/FunctionalButton.vue";

export default {
  components: { FunctionalButton },
  data() {
    return {
      titleChoices: {
        "customer-detail": "顧客情報",
        "customer-action": "履歴一覧",
      },
    };
  },
  computed: {
    canCloseTab() {
      // js code can close tab if it on top-level windows that have a single history entry
      return window.history.length <= 1;
    },
    title() {
      return this.titleChoices[this.$route.name];
    },
  },
  methods: {
    closeTab() {
      window.close();
    },
  },
};
</script>
